/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useState, useEffect, useRef } from "react";
import {
  KeyIcon,
  UserCircleIcon,
  CreditCardIcon,
  UserGroupIcon,
  PlusIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import Uploader from "./Uppy";
// import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { CalendarIcon, MegaphoneIcon } from "@heroicons/react/24/outline";
import { qList } from "../modules/_assessment_questions";
import Badge from "./Badge";
import AssessmentTemplateQuestionCard from "./AssessmentTemplatesQuestCard";
import Divider from "./Divider";
import { sockets } from "../modules/_notifications";
import { _auth } from "../modules/_auth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AssessmentTemplateQuestionEditor(props) {
  // const [selectedTab, setSelectedTab] = useState(navigation[0].name);
  const [blurbs, setBlurbs] = useState([{}]);
  const [questions, setQuestions] = useState([{}]);
  const [originalQuestions, setOriginalQuestions] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [createQuestion, setCreateQuestion] = useState(false);
  const assessment_type = props.assessment_type;

  const create = props.create ? props.create : true;
  const [newObject, setNewObject] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const addQuestionRef = props.addQuestionRef;
  const profile = _auth.getUserProfile();

  useEffect(() => {
    setCompetencies(props.competencies);
  }, [props.competencies]);
  // const socket = sockets.connect();
  //useRef scroll into view

  // const setAddQuestionOption = () => {
  //   props.setAddQuestionOption(true);
  // };

  // useEffect(() => {
  //   (async () => {
  //     socket.on("import-success", async function () {
  //       const questions = await qList.GetQuestions(
  //         assessment_type ? assessment_type : null
  //       );
  //       if (questions) {
  //         setQuestions(questions);
  //       }
  //     });
  //   })();
  // }, [socket]);

  useEffect(() => {
    (async () => {
      const questions = props.questions;

      //const questions = await qList.GetQuestions(assessment_type);
      //
      if (questions) {
        setQuestions(questions);
        setOriginalQuestions(questions);
      }
    })();
  }, [props.modalOpen ? props.modalOpen : null]);

  useEffect(() => {
    const blurbs = create == true ? props.modalItem : props.modalItem["blurbs"];

    setBlurbs(blurbs);

    setLoading(false);
  }, []);

  const addQuestion = () => {
    setCreateQuestion(true);

    let questionTemplate =
      props.assessment_type == "impediment"
        ? {
            Index: 0,
            Competency:
              props.selectedCompetency && props.selectedCompetency !== null
                ? competencies &&
                  competencies.length > 0 &&
                  competencies.filter((c) => {
                    //
                    //
                    return c.id == props.selectedCompetency._id;
                  })[0]
                : null,
            // Competency:
            //   props.selectedCompetency && props.selectedCompetency !== null
            //     ? props.competencies &&
            //       props.competencies.length > 0 &&
            //       props.competencies.filter(
            //         (c) => c._id == props.selectedCompetency
            //       )
            //     : null,
            // props.selectedCompetency && props.selectedCompetency !== null
            //   ? props.selectedCompetency._id
            //   : null,

            score: 0,
            effort: 0,
            Description: "",
            Category: "",
            Subcategory: "",
            // Team: profile.tenantName,
            Claimed: true,
            // Claimed_by_person: profile.userId,
            // Claimed_by_team: profile.tenantId,
            tenant_id: profile.tenantId,
            company_id: profile.companyId,
            ReportedByTeams: [profile.tenantInfo.tenantTitle],
            // teams_impacted: [
            //   {
            //     claimed_by_team: {
            //       _id: profile.tenantId,
            //     },
            //     effort: 0,
            //     score: 0,
            //     claimed_by_person: {
            //       _id: profile.userId,
            //     },
            //     Resolved: false,
            //   },
            // ],
          }
        : {
            question_type: assessment_type,
            question: "",
            best_answer: null,
            // answer_options: [],
            //if answer ops is null, map through and create a new array with 4 nulls
            //if type is true/false, answer options should be [true, false]
            //if type is multiple choice, answer options should be [null, "", "", ""]
            //if type is  yes/no, answer options should be [yes, no, unsure]
            action_plan: "",
            type: null,
          };

    const newState = [...questions, questionTemplate];
    setQuestions(newState);
  };

  const removeQuestionByIndex = (index) => {
    const newQuestions = questions.filter((q, i) => i !== index);
    setQuestions(newQuestions);
    props.setQuestions(newQuestions);
  };

  const removeImpedimentByIndex = (index) => {
    const newQuestions = questions.filter((q, i) => i !== index);
    setQuestions(newQuestions);
    props.setQuestions(newQuestions);
  };

  const updateQuestionsByIndex = (index, update) => {
    const field = Object.keys(update)[0];
    const value = Object.values(update)[0];

    setQuestions((questions) => {
      const newQuestions = [...questions];
      newQuestions[index][field] = value;
      return newQuestions;
    });
  };

  const updateCompetencies = (newData) => {
    props.refreshQuestions(newData);

    // const newQuestions = questions.map((q) => {
    //   if (q._id == question._id) {
    //     return question;
    //   } else {
    //     return q;
    //   }
    // });
    // setQuestions(newQuestions);
  };

  const replaceQuestion = (question) => {
    const newQuestions = questions.map((q) => {
      if (q._id == question._id) {
        return question;
      } else {
        return q;
      }
    });
    setQuestions(newQuestions);
  };

  useEffect(() => {}, [questions]);

  return (
    <>
      <div className=" sm:overflow-hidden sm:rounded-md">
        {questions && questions.length < 1 && (
          <div className="space-y-2  py-6 px-4 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-slate-900 ">
                {props.title ? props.title : "Question"} Editor
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                {props.description
                  ? props.description
                  : "Make changes to this assessment's questions and answers below. Note that this will only apply to newly created assessments."}
              </p>
            </div>
          </div>
        )}
      </div>
      {/* <form action="#" method="POST"> */}
      {questions.map((question, index) => {
        const type = question.type ? question.type : "Multiple Choice";
        //
        return (
          <AssessmentTemplateQuestionCard
            key={index}
            question={question}
            type={
              props.assessment_type !== "impediment"
                ? props.assessment_type
                : "impediment"
            }
            index={index}
            from={props.from}
            replaceQuestion={replaceQuestion}
            updateCompetencies={(update) => updateCompetencies(update)}
            competencies={competencies}
            create={create}
            createQuestion={createQuestion}
            removeQuestion={(index) => removeQuestionByIndex(index)}
            removeImpediment={(index) => removeQuestionByIndex(index)}
            updateQuestion={(index, update) =>
              updateQuestionsByIndex(index, update)
            }
          />
        );
      })}
      {create && ( //we need to find a constant or allow adding to the number of questions on update
        <div id="divider" ref={addQuestionRef}>
          <Divider
            // ref={addQuestionRef}
            handleClick={() => addQuestion()}
            buttonText={
              props.assessment_type !== "impediment"
                ? "New Question"
                : "New Impediment"
            }
          />
        </div>
      )}
      {/* <div className="px-4 py-20 bg-gray-50 text-right sm:px-6"></div> */}
      {/* </form> */}
    </>
    //   </div>
    // </div>
  );
}
