const data = [
  {
    _id: "6482833b9ee37e6850906bd0",
    name: "Plan 1",
    goal_date: "2021-06-30T00:00:00.000Z",
    Competency: "60c9b0b3b4b8b71e9c3e0b1e",
    Competencies: [
      {
        _id: "64095329540ff64015e7c210",
        key: "COMP-01",
        Area: "Culture",
        "Value Drivers": "",
        Name: "Collaboration",
        Description:
          "Ensures the team has visibility into other department's work and its impact on team predictability.",
        question:
          "Tell us which option below best describes your team's current situation. ",
        best_answer:
          "Regular communication and sharing across operations, development, and security. Team members know who to report security concerns to.",
        answer_options: [
          "Siloed by functional team.",
          "Limited to Dev and Ops teams. Security remains siloed, and team members don't know who to report security concerns to.",
          "Security stakeholders regularly share with Dev and Ops teams but not as frequently as Dev and Ops teams share.",
          "Regular communication and sharing across operations, development, and security. Team members know who to report security concerns to.",
        ],
        action_plan:
          "Start small with understanding processes and their impact to your value stream.  Use this knowledge to clear other impediments and improve more targeted metrics.",
        criticality: "",
        solution_provider_bucket: [""],
        Notes: "",
        hover_question: "",
        hover_answer: "",
        action_title: "Break down silos",
        question_type: "comp",
        tenant_id: "63d33a7453bd031252458ae3",
        createdAt: "2023-03-09T03:31:53.455Z",
        updatedAt: "2023-05-13T21:16:51.007Z",
        __v: 3,
        question_types: ["comp"],
        impediments: [
          {
            _id: "646d06c7e156ee222cdd14f0",
            Index: 7,
            score: 0,
            effort: 0,
            Description: "Automatically create standard CRs",
            Category: "Process",
            Escalated: false,
            Org_level_resolution_required: false,
            Dependencies: [],
            Team: "ADS_DEPLOY",
            ReportedByTeams: [
              "Moonwalkers-Callisto",
              "Moonwalkers-Io",
              "Chaos Monkeys",
            ],
            Claimed: false,
            Comments: [""],
            Resolved: false,
            tenant_id: "63d33a7453bd031252458ae3",
            company_id: "63d33a7453bd031252458ae9",
            teams_impacted: [
              {
                claimed_by_team: "63d33a7453bd031252458ae3",
                effort: 12,
                score: 12,
                claimed_by_person: "63d33a7253bd031252458add",
                Resolved: false,
                _id: "647fe630aca6bc630101aed5",
                resolveDate: "2023-06-07T15:00:43.243Z",
                status: "To Do",
              },
            ],
            createdAt: "2023-05-23T18:32:39.565Z",
            updatedAt: "2023-06-08T21:34:53.626Z",
            __v: 20,
            Title: "Automatically create standard CR's",
            Competency: "64095329540ff64015e7c210",
            Subcategory: "Change Management",
            Scope: "Cross-Domain",
          },
          {
            _id: "646d06c7e156ee222cdd14f6",
            Index: 10,
            score: 0,
            effort: 0,
            Description: "Monitoring maturity",
            Category: "Measurement",
            Escalated: false,
            Org_level_resolution_required: false,
            Dependencies: [],
            Team: "ADS_DEPLOY",
            ReportedByTeams: ["Moonwalkers-Callisto"],
            Claimed: false,
            Comments: [""],
            Resolved: false,
            tenant_id: "63d33a7453bd031252458ae3",
            company_id: "63d33a7453bd031252458ae9",
            teams_impacted: [
              {
                claimed_by_team: "63d33a7453bd031252458ae3",
                effort: 0,
                score: 0,
                claimed_by_person: "63d33a7253bd031252458add",
                Resolved: false,
                _id: "648295a82eb78c566ad4265f",
                status: "To Do",
              },
            ],
            createdAt: "2023-05-23T18:32:39.568Z",
            updatedAt: "2023-06-09T02:59:52.480Z",
            __v: 3,
            Title: "",
            Competency: "64095329540ff64015e7c210",
          },
        ],
      },
      {
        _id: "64095329540ff64015e7c211",
        key: "COMP-02",
        Area: "Culture",
        "Value Drivers": "",
        Name: "Information Flows",
        Description:
          "Ensures work the team is doing is visible across the organization.",
        question:
          "Tell us which option below best describes your team's current situation. ",
        best_answer:
          "Information is freely available from functional teams throughout the company",
        answer_options: [
          "Siloed by functional team.",
          "Limited information flows from functional teams.",
          "Information is freely available from functional teams throughout the department",
          "Information is freely available from functional teams throughout the company",
        ],
        action_plan:
          "Start small with understanding current sources of information for your customers (internal and external).  Use this knowledge to clear other impediments and improve more targeted metrics.",
        criticality: "",
        solution_provider_bucket: [""],
        Notes: "",
        hover_question: "",
        hover_answer: "",
        action_title: "Break down silos",
        question_type: "comp",
        tenant_id: "63d33a7453bd031252458ae3",
        createdAt: "2023-03-09T03:31:53.455Z",
        updatedAt: "2023-05-13T21:16:51.008Z",
        __v: 3,
        question_types: ["comp"],
        impediments: [
          {
            _id: "646d06c7e156ee222cdd155c",
            Index: 11,
            score: 0,
            effort: 0,
            Description: "New Relic alert policy ramp up",
            Category: "Measurement",
            Escalated: false,
            Org_level_resolution_required: false,
            Dependencies: [],
            Team: "ADS_DEPLOY",
            ReportedByTeams: [],
            Claimed: false,
            Comments: [""],
            Resolved: false,
            tenant_id: "63d33a7453bd031252458ae3",
            company_id: "63d33a7453bd031252458ae9",
            teams_impacted: [
              {
                claimed_by_team: "63d33a7453bd031252458ae3",
                effort: 0,
                score: 0,
                claimed_by_person: "63d33a7253bd031252458add",
                Resolved: false,
                _id: "648295aa2eb78c566ad42666",
                status: "To Do",
              },
            ],
            createdAt: "2023-05-23T18:32:39.588Z",
            updatedAt: "2023-06-09T02:59:54.441Z",
            __v: 1,
            Competency: "64095329540ff64015e7c211",
          },
        ],
      },
    ],
    tenantId: "63d33a7453bd031252458ae3",
    companyId: "60c9b0b3b4b8b71e9c3e0b1c",
    status: "Not Started",
  },
];
export { data };
