import React, { useState } from "react";
import { useEffect, useMemo } from "react";
import { useRef } from "react";

import { useHistory } from "react-router-dom";
import MultiSelectListBox from "../components/MultiSelectListBox";
import Table, {
  SelectColumnFilter,
  GlobalFilter,
} from "../components/ReactTable";
import Tabs from "../pages/component/Tabs";
import Button from "../components/Button";
import { FeatureFlag } from "../components/FeatureFlag";
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  CalendarIcon,
  CheckIcon,
  ClipboardDocumentListIcon,
  DocumentPlusIcon,
  PlusCircleIcon,
  UserMinusIcon,
  UserPlusIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { _impediments } from "../modules/impediments";
import { _competencies } from "../modules/competencies";
import {
  LogoutIcon,
  MinusCircleIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import Tooltip from "../components/Tooltip";
import AssessmentTemplateQuestionEditor from "../components/AssessmentTemplatesQuestEditor";
import ModalBasic from "../components/ModalBasic";
import RightActionArea from "../components/ActionAreaRight";
import { ToggleButtons } from "../components/ReactTable";
import { _auth } from "../modules/_auth";
import { Icon } from "@iconify/react";
import ImpedimentList from "../components/ImpedimentList";
import Skeleton from "../components/Skeleton";
import { utils } from "../modules/_utils";
import Paragraph from "../components/Paragraph";
import { clear } from "@testing-library/user-event/dist/clear";
import Toggle from "../components/Toggle";
import DialogBox from "../components/Dialog";
import CommandPalette from "../components/CommandPalette";
import ComboboxSelector from "../components/ComboBoxSelector";
import { _teams } from "../modules/_teams";
import Menu2 from "../components/Menu2";
import { RenderImpedimentSidebar } from "./Impediments/ImpedimentSidebar";
import { exports } from "../modules/_exportToExcel";
export function RenderInfoColumn(props) {
  const [resolved, setResolved] = useState(
    props.Resolved == true ? true : false
  );
  const [rdValue, setRdValue] = useState();
  const getCategory = (area) => {
    if (area) {
      switch (area) {
        case "Culture":
          return "R&D";
        case "Plan":
          return "R&D";
        case "Build":
          return "R&D";
        case "Test":
          return "R&D";
        case "Deploy":
          return "Ops";
        case "Operate":
          return "Ops";
        case "Monitor":
          return "Ops";
        case "Respond":
          return "Ops";
        default:
          return "R&D";
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (props.Competency) {
      setRdValue(getCategory(props.Competency.Area));
    }
  }, [props.Competency]);
  useEffect(() => {
    setResolved(props.Resolved == true ? true : false);
  }, [props.Resolved]);

  return (
    <div className="flex space-x-2">
      <div>
        {resolved ? (
          <Tooltip
            className={"z-50 text-xs text-slate-500"}
            position={"right"}
            size="md"
            description={"This impediment has been resolved."}
            title={"Resolved"}
          >
            <Icon
              icon="ic:round-check"
              className="mr-1 my-auto h-5 w-5 text-emerald-500"
            />
          </Tooltip>
        ) : (
          <Tooltip
            className={"z-50 text-xs text-slate-500"}
            position={"right"}
            size="md"
            description={"This impediment has not been resolved."}
            title={"Not Resolved"}
          >
            <Icon
              className="mr-1 my-auto h-5 w-5 text-red-500"
              icon="material-symbols:info-outline-rounded"
            />
          </Tooltip>
        )}
      </div>
      <div>
        {rdValue && (
          <div className="text-slate-700">
            {rdValue && (
              <Tooltip
                className={"  text-slate-600"}
                position={"right"}
                size="md"
                description={
                  "This impediment has been automatically classified based on the area of the DevOps model the associated Competency is in."
                }
                title={"Category"}
              >
                {rdValue}
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>

    //   {resolved ? (

    //   <Icon icon="ic:round-check" className="mr-1 my-auto h-5 w-5 text-emerald-500"/>
    //   ) : (

    //   <Icon
    //     className="mr-1 my-auto h-5 w-5 text-red-500"
    //     icon="material-symbols:info-outline-rounded"
    //   />
    //   )
    //   }
    // );
  );
}

export function RenderActions({
  updateData,
  dedupeMode,
  component,
  setUpdatedId,
  row,
  idx,
  setSelectedRow,
  showDelete,
  showViewDetails,
  showResolveImpediment,
  showClaim,
}) {
  const [loadingClaimButton, setLoadingClaimButton] = useState(false);
  const [loading, setLoading] = useState([]);
  const [isResolvedLoading, setIsResolvedLoading] = useState(false);
  const [isResolvedSuccess, setIsResolvedSuccess] = useState(false);
  const [resolveDisabled, setResolveDisabled] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [buttonAction, setButtonAction] = useState();
  const [isResolved, setIsResolved] = useState();
  // row.Resolved && row.Resolved !== true ? false : true
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [claimedByTeam, setClaimedByTeam] = useState();
  const [team, setTeam] = useState();
  const [plusOne, setPlusOne] = useState(false);
  const [pluseoneTeams, setPlusOneTeams] = useState([]);
  const [isImpacted, setIsImpacted] = useState(false);
  const [success, setSucces] = useState(false);
  const profile = _auth.getUserProfile();
  const history = useHistory();
  const [data, setData] = useState([]);

  showResolveImpediment =
    dedupeMode != true && component != "sidebar" ? true : showResolveImpediment;
  showClaim = dedupeMode != true && component != "sidebar" ? true : showClaim;
  // useEffect(() => {
  //   showDelete = showDelete == undefined ? true : showDelete;
  //   showViewDetails = showViewDetails == undefined ? true : showViewDetails;
  // }, [showDelete, showViewDetails]);

  useEffect(() => {}, [row]);

  useEffect(() => {
    setIsClaimed(row && row.Claimed ? true : false);
    setResolveDisabled(row && !row.Claimed);
  }, [row && row.Claimed ? row.Claimed : row]);

  // useEffect(() => {
  //   setResolveDisabled(!isClaimed);
  // }, [isClaimed]);

  useEffect(() => {
    setData(row);
    const team = utils.findTeam(row, profile.tenantId, "Impediments");
    if (team) {
      setTeam(team);
      setIsResolved(team.Resolved);
    }
  }, [row && row]);

  // useEffect(() => {

  //   if (team) {
  //     setIsResolved(team.resolved);
  //   }
  // }, [team]);

  // useEffect(() => {
  //   //
  //   setClaimedByTeam(row.ClaimedByTeam);
  // }, [row.ClaimedByTeam]);

  useEffect(() => {
    let index;

    if (row && row.teams_impacted && row.teams_impacted.length > 0) {
      index =
        row &&
        row.teams_impacted.findIndex((x) => {
          if (x.claimed_by_team && "_id" in x.claimed_by_team) {
            return x.claimed_by_team._id === profile.tenantId;
          } else {
            index = -1;
          }
        });
    }

    if (index > -1) {
      // const team = row.teams_impacted[index];
      // setIsResolved(team.resolved);
      setIsImpacted(true);
    } else {
      // setIsResolved(false);
      setIsImpacted(false);
    }
  }, [row]);

  // useEffect(() => {

  //   const team = utils.findTeam(row, profile.tenantId);
  //   if (team) {

  //     setIsResolved(team.resolved);
  //   }
  // }, [row.teams_impacted]);

  //
  // const impedimentId = row.id;

  const deleteImpediment = async (impedimentId) => {
    setShowDeleteConfirmDialog(false);

    const deleted = await _impediments.deleteImpediment(row._id);
    if (deleted) {
      updateData(row, null, null, null, "delete");
    } else {
    }
  };
  const handleDeleteImpedimentClick = async (e, impedimentId) => {
    e.preventDefault();

    setShowDeleteConfirmDialog(true);
    //set dialog to open
  };

  const handleClaimImpediment = async (e, impedimentId, claimed, rowIndex) => {
    // add team id to impediment
    // add claim by person id
    //set loading for 2 second
    e.preventDefault();

    //push impedimentId to loadingClaimButton array

    setLoading((loading) => [...loading, "Claim"]);
    setButtonAction("Claim");
    // setLoadingClaimButton({ ...loadingClaimButton, [impedimentId]: true });
    claimed = !claimed;
    //get index of impedimentId in employees array
    // const index = employees.findIndex((emp) => emp._id === impedimentId);

    const updated = await _impediments.claimImpediment(impedimentId, claimed);

    if (updated) {
      if (claimed) {
        let fieldsToUpdate = [
          { colName: "Claimed", value: claimed, rowIndex: rowIndex },
          {
            colName: "Claimed_by_team.tenantTitle",
            value: updated.Claimed_by_team
              ? updated.Claimed_by_team.tenantTitle
              : null,

            rowIndex: rowIndex,
          },
        ];
        setTimeout(function () {
          setLoading((loading) => loading.filter((item) => item !== "Claim"));
          setButtonAction("Claim");
          setIsClaimed(claimed);
          setResolveDisabled(!claimed);
          setSucces(true);
        }, 750); //show checkmark for 1 second
        setTimeout(function () {
          setUpdatedId(impedimentId);
        }, 2000);
        setTimeout(function () {
          updateData(updated, impedimentId, row, fieldsToUpdate); //remove this if it gets confusing
        }, 3000);
        //cleanup
        setTimeout(function () {
          setSucces(false);
          setButtonAction(null);
        }, 3000);
      } else {
        let fieldsToUpdate = [
          { colName: "Claimed", value: claimed, rowIndex: rowIndex },
          {
            colName: "Claimed_by_team.tenantTitle",
            value: null,
            rowIndex: rowIndex,
          },
        ];
        setTimeout(function () {
          setButtonAction("Claim");
          setLoading((loading) => loading.filter((item) => item !== "Claim"));

          setIsClaimed(claimed);
          updateData(updated, impedimentId, row, fieldsToUpdate);
          setButtonAction(null);
        }, 1500);
      }
    }

    // const updated = _impediments.claimImpediment(impedimentId);
  };
  const handlePlusOneImpediment = async (
    e,
    impedimentId,
    claimed,
    rowIndex
  ) => {
    e.preventDefault();
    //check if tenantID is in

    //push impedimentId to loadingClaimButton array

    setLoading((loading) => [...loading, "PlusOne"]);
    setButtonAction("PlusOne");

    let newImpacted = !isImpacted;

    const updated = await _impediments.plusOneImpediment(row._id, newImpacted);

    if (updated) {
      let fieldsToUpdate = [
        {
          colName: "teams_impacted",
          value: updated.teams_impacted,
          rowIndex: rowIndex,
        },
      ];
      setTimeout(function () {
        setButtonAction("PlusOne");
        setLoading((loading) => loading.filter((item) => item !== "PlusOne"));
        // setPlusOne(newImpacted);
        setIsImpacted(newImpacted);
        setSucces(true);
      }, 100); //750 show checkmark for 1 second
      setTimeout(function () {
        setUpdatedId(impedimentId);
      }, 200); //1500
      setTimeout(function () {
        updateData(updated, impedimentId, row, fieldsToUpdate); //remove this if it gets confusing
      }, 300); //2500
      //cleanup
      setTimeout(function () {
        setButtonAction(null);
        setSucces(false);
      }, 200); //2000
    } else {
      setTimeout(function () {
        setButtonAction("PlusOne");
        setLoading((loading) => loading.filter((item) => item !== "PlusOne"));
        // setPlusOne(newImpacted);
        setIsImpacted(newImpacted);
        setSucces(true);
        updateData(updated, impedimentId, row, null);
      }, 100); //750 show checkmark for 1 second
      setTimeout(function () {
        setUpdatedId(impedimentId);
      }, 200); //2000
      setTimeout(function () {
        updateData(updated, impedimentId, row, null); //remove this if it gets confusing
      }, 300); //3000
      //cleanup
      setTimeout(function () {
        setButtonAction(null);
        setSucces(false);
      }, 300); //3000
    }

    // const updated = _impediments.claimImpediment(impedimentId);
  };
  const handleResolveImpediment = async (
    e,
    impedimentId,
    resolve,
    rowIndex
  ) => {
    e.preventDefault();
    //check if tenantID is in

    //push impedimentId to loadingClaimButton array

    setLoading((loading) => [...loading, "Resolve"]);
    setButtonAction("Resolve");

    let newResolved = !isResolved;

    const updated = await _impediments.resolveImpediment(row._id, newResolved);

    if (updated) {
      let fieldsToUpdate = [
        {
          colName: "teams_impacted",
          value: updated.teams_impacted,
          rowIndex: rowIndex,
        },
      ];

      setTimeout(function () {
        setButtonAction("Resolve");
        setLoading((loading) => loading.filter((item) => item !== "Resolve"));
        // setPlusOne(newImpacted);
        setIsResolved(newResolved);
        setSucces(true);
      }, 750); //show checkmark for 1 second
      setTimeout(function () {
        setUpdatedId(impedimentId);
      }, 1500);
      setTimeout(function () {
        updateData(updated, impedimentId, row, fieldsToUpdate); //remove this if it gets confusing
      }, 2500);
      //cleanup
      setTimeout(function () {
        setButtonAction(null);
        setSucces(false);
      }, 2000);
    } else {
      let fieldsToUpdate = [
        {
          colName: "teams_impacted",
          value: updated.teams_impacted,
          rowIndex: rowIndex,
        },
      ];
      setTimeout(function () {
        setButtonAction("Resolve");
        setLoading((loading) => loading.filter((item) => item !== "Resolve"));
        setIsResolved(newResolved);
        setSucces(true);
        updateData(updated, impedimentId, row, fieldsToUpdate);
      }, 750);
      setTimeout(function () {
        setUpdatedId(impedimentId);
      }, 2000);
      setTimeout(function () {
        updateData(updated, impedimentId, row, fieldsToUpdate); //remove this if it gets confusing
      }, 3000);
      //cleanup
      setTimeout(function () {
        setButtonAction(null);
        setSucces(false);
      }, 3000);
    }

    // const updated = _impediments.claimImpediment(impedimentId);
  };
  // const handleResolveImpediment = async (
  //   e,
  //   impedimentId,
  //   resolve,
  //   rowIndex
  // ) => {
  //   setIsResolvedLoading(true);
  //   // setLoading((loading) => [...loading, "Resolve"]);

  //   resolve = !resolve;

  //   const resolved = await _impediments.resolveImpediment(
  //     impedimentId,
  //     null,
  //     "Resolved",
  //     resolve
  //   );
  //   let fieldsToUpdate = [
  //     { colName: "Resolved", value: resolve, rowIndex: rowIndex },
  //   ];
  //   if (resolved) {

  //     setTimeout(function () {
  //       setIsResolvedLoading(false);
  //       // setLoading((loading) => loading.filter((item) => item !== "Resolve"));
  //       setIsResolved(!isResolved);
  //       setIsResolvedSuccess(true);
  //     }, 700);

  //     setTimeout(function () {
  //       updateData(resolved, impedimentId, row, fieldsToUpdate); //remove this if it gets confusing
  //     }, 1500);
  //     setTimeout(function () {
  //       setIsResolvedSuccess(false);
  //     }, 2000);
  //   }
  // };
  // const handleResolveImpediment = async (
  //   e,
  //   impedimentId,
  //   resolve,
  //   rowIndex
  // ) => {
  //   setIsResolvedLoading(true);
  //   // setLoading((loading) => [...loading, "Resolve"]);

  //   resolve = !resolve;

  //   const resolved = await _impediments.resolveImpediment(
  //     impedimentId,
  //     null,
  //     "Resolved",
  //     resolve
  //   );
  //   let fieldsToUpdate = [
  //     { colName: "Resolved", value: resolve, rowIndex: rowIndex },
  //   ];
  //   if (resolved) {

  //     setTimeout(function () {
  //       setIsResolvedLoading(false);
  //       // setLoading((loading) => loading.filter((item) => item !== "Resolve"));
  //       setIsResolved(!isResolved);
  //       setIsResolvedSuccess(true);
  //     }, 700);

  //     setTimeout(function () {
  //       updateData(resolved, impedimentId, row, fieldsToUpdate); //remove this if it gets confusing
  //     }, 1500);
  //     setTimeout(function () {
  //       setIsResolvedSuccess(false);
  //     }, 2000);
  //   }
  // };
  const handleViewDetailsInSidebar = (e, impedimentId) => {
    e.preventDefault();
    setSelectedRow(row);
  };
  const handleViewDetails = async (e, impedimentId) => {
    e.preventDefault();

    let state = {
      data: row.row.original,
      list: row.data,
    };
    let path = "/impediments/details";
    return history.push({
      pathname: path,
      state: state,
    });
  };
  return (
    <div className="flex items-right items-center space-x-3">
      <>
        {/* <Button
          size="xs"
          grow
          // rounded
          iconButton
          // loader={loadingClaimButton[impedimentId]}
          loader={
            // if "Claim" is in loading array, show loader
            loading.includes("Claim") ? true : false
          }
          disabled={
            isClaimed == true &&
            row.Claimed_by_team !== null &&
            row.Claimed_by_team._id !== profile.tenantId &&
            profile.tenantInfo.type !== "admin"
          }
          // className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
          handleClick={(e) =>
            handleClaimImpediment(
              e,
              row._id,
              isClaimed == true ? true : false,
              idx
            )
          }
        >
          {!loading.includes("Claim") &&
            buttonAction == "Claim" &&
            success == true && <CheckIcon className="w-5 h-5 text-green-500" />}
          {!loading.includes("Claim") &&
            isClaimed !== true &&
            buttonAction != "Claim" && (
              <PlusIcon className="h-5 w-5 transition-all ease-in-out hover:scale-110 duration-300" />
            )}
          {!loading.includes("Claim") &&
            isClaimed == true &&
            buttonAction != "Claim" && (
              <MinusCircleIcon className="h-5 w-5 transition-all ease-in-out hover:scale-110 duration-300" />
            )}
        </Button> */}
        {/* impacted team button */}
        {isImpacted && showResolveImpediment && (
          <Button
            size="xxs"
            // disabled={isImpacted == false}
            color={
              loading.includes("Resolve")
                ? "text"
                : isResolved
                ? "text"
                : !isResolved &&
                  !loading.includes("Resolve") &&
                  success &&
                  buttonAction == "Resolve"
                ? "text"
                : "outline"
            }
            // rounded
            // iconButton
            // loader={loadingClaimButton[impedimentId]}
            loader={
              // if "Claim" is in loading array, show loader
              loading.includes("Resolve") ? true : false
            }
            // disabled={
            //   props.Claimed_by_team &&
            //   props.Claimed_by_team.name !== "JDO" &&
            //   props.Claimed_by_team !== ""
            // }
            // className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
            handleClick={(e) =>
              handleResolveImpediment(e, row._id, row.Resolved ? true : false)
            }
          >
            <Tooltip
              size="md"
              title="Resolve Impediment"
              hidden={true}
              description={"Use this button to mark your impediment resolved."}
            >
              {!loading.includes("Resolve") &&
                buttonAction == "Resolve" &&
                success == true && (
                  <span className="flex ">
                    <CheckIcon className="flex w-5 h-5 text-green-500" />
                  </span>
                )}
              {
                !loading.includes("Resolve") &&
                  isResolved !== true &&
                  buttonAction != "Resolve" && (
                    // <CheckIcon className="w-5 h-5 text-gray-500 transition-all ease-in-out hover:scale-110 duration-300" />
                    <span className="flex items-center">
                      <Icon
                        icon="material-symbols:data-check-rounded"
                        className="mr-2 flex h-5 w-5 transition-all ease-in-out hover:scale-110 duration-300"
                      />
                      Resolve{" "}
                    </span>
                  )

                // <PlusIcon className="h-5 w-5 transition-all ease-in-out hover:scale-110 duration-300" />
              }
              {!loading.includes("Resolve") &&
                isResolved == true &&
                buttonAction != "Resolve" && (
                  // <MinusCircleIcon className="h-5 w-5 transition-all ease-in-out hover:scale-110 duration-300" />
                  <span className="flex text-green-500">
                    <Icon
                      icon="ic:round-task-alt"
                      className="flex mr-2 h-5 w-5 text-green-500 transition-all ease-in-out hover:scale-110 duration-300"
                    />{" "}
                    Resolved{" "}
                  </span>
                )}
            </Tooltip>
          </Button>
        )}

        {showClaim && (
          <Button
            className={"flex items-center"}
            size="xxs"
            color={
              loading.includes("PlusOne")
                ? "text"
                : isImpacted
                ? "text"
                : !isImpacted &&
                  !loading.includes("PlusOne") &&
                  success &&
                  buttonAction == "PlusOne"
                ? "text"
                : "outline-indigo"
            }
            // iconButton
            // loader={true}
            loader={loading.includes("PlusOne") ? true : false}
            disabled={
              false
              // isClaimed == true &&
              // row.Claimed_by_team !== null &&
              // row.Claimed_by_team._id !== profile.tenantId &&

              // profile.tenantInfo.type !== "admin"
            }
            // className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
            handleClick={(e) =>
              handlePlusOneImpediment(e, row._id, isImpacted, idx)
            }
          >
            {" "}
            <Tooltip
              size="md"
              title="Impacted Team"
              hidden={true}
              description={
                "Use this button to add or remove yourself to this impediment."
              }
            >
              {!loading.includes("PlusOne") &&
                buttonAction == "PlusOne" &&
                success == true && (
                  <span className="flex items-center">
                    <CheckIcon className="h-4 w-4  text-green-500" />
                  </span>
                )}

              {!loading.includes("PlusOne") &&
                isImpacted !== true &&
                buttonAction != "PlusOne" && (
                  <span className="flex items-center">
                    <UserPlusIcon className="mr-2 h-4 w-4  text-indigo-500 transition-all ease-in-out hover:scale-110 duration-300" />
                    Claim
                  </span>
                )}
              {!loading.includes("PlusOne") &&
                isImpacted == true &&
                buttonAction != "PlusOne" && (
                  <span className="flex items-center text-indigo-500">
                    <UserMinusIcon className="mr-2 h-4 w-4  text-indigo-500 transition-all ease-in-out hover:scale-110 duration-300" />
                    Remove
                  </span>
                )}
            </Tooltip>
          </Button>
        )}
        {profile &&
          profile.tenantInfo.type == "admin" &&
          showDelete &&
          showDelete == true && (
            <Button
              iconButton
              className="flex items-center"
              audit={true}
              action="Delete Impeditment"
              details={{}}
              handleClick={(e) => handleDeleteImpedimentClick(e, row._id)}
              size="xs"
              color="red"
            >
              {/* delete icon */}
              <Icon
                icon="ic:round-delete"
                className="h-6 w-6 text-red-500 transition-all ease-in-out hover:scale-110 duration-300"
              />
            </Button>
          )}
        {showViewDetails == true && (
          <Button
            size="xxs"
            iconButton
            color={"red"}
            loader={false}
            handleClick={(e) => handleViewDetailsInSidebar(e, row._id)}
          >
            <Icon icon="material-symbols:open-in-new" className="h-5 w-5" />
          </Button>
        )}
      </>
      {showDeleteConfirmDialog && (
        <DialogBox
          handleConfirmClick={(data) => deleteImpediment(data)}
          handleCancelClick={null}
          loading={false}
          open={showDeleteConfirmDialog}
          success={success}
          title="Confirm Impediment Delete"
          message={"Are you sure you want to delete this impediment?"}
          OKbuttonText={"Delete"}
          CancelButton={"Cancel"}
          audit={true}
          action={"Delete impediment"}
          auditDetails={{ id: row._id, description: row.description }}
          data={row._id}
        />
      )}
    </div>
  );
}

export function RenderDynamicRDCell(props) {
  const getCategory = (area) => {
    if (area) {
      switch (area) {
        case "Culture":
          return "R&D";
        case "Plan":
          return "R&D";
        case "Build":
          return "R&D";
        case "Test":
          return "R&D";
        case "Deploy":
          return "Ops";
        case "Operate":
          return "Ops";
        case "Monitor":
          return "Ops";
        case "Respond":
          return "Ops";
        default:
          return "R&D";
      }
    } else {
      return null;
    }
  };
  const [value, setValue] = useState();
  // props.Competency
  //   ? getCategory(props.Competency.Area)
  //   : null
  useEffect(() => {
    if (props.Competency) {
      //
      setValue(getCategory(props.Competency.Area));
    }
  }, [props.Competency]);

  return (
    <div className="text-green-700 flex items-center space-x-3">
      {value && value}
    </div>
  );
}

export function RenderImpactedTeams({ row }) {
  const [items, setItems] = useState();

  useEffect(() => {
    if (row && row.impacted_teams) {
      setItems(row.impacted_teams);
    }
  }, [row && row.impacted_teams]);

  return (
    // transform transition duration-150 ease-in-out ${
    //   card ? "translate-x-0" : "translate-x-full"
    // }
    <div
      className={`flex -space-x-8 hover:-space-x-3  
      
      transition-all ease-in-out  duration-150 items-center`}
    >
      {row &&
        row.teams_impacted &&
        row.teams_impacted.slice(0, 3).map((tag, idx) => {
          return (
            tag.claimed_by_team &&
            tag.claimed_by_team.tenantTitle && (
              <span
                class="flex items-center h-6 text-xs font-medium text-indigo-600  bg-indigo-200 px-5 border-white border-2  rounded-full "
                alt=""
                key={idx}
              >
                {tag.claimed_by_team.tenantTitle}
              </span>
            )
          );
        })}
      {row && row.teams_impacted && row.teams_impacted.length > 3 && (
        <a
          class="flex items-center justify-center p-2 h-8 w-8 text-xs 
          font-medium text-white bg-indigo-900 border-2 border-white rounded-full 
          transition-all ease-in-out hover:scale-110 duration-150"
          href="#"
        >
          +{row.teams_impacted.length - 3}
        </a>
      )}
    </div>
  );
}

export function RenderTeamName({
  row,
  setReportedByTeamModalOpen,
  teams,
  handleCommandClick,
  updateData,
  profile,
}) {
  const [teamName, setTeamName] = useState();
  const [open, setOpen] = useState(false);
  const [teamNames, setTeamNames] = useState();
  const [teamMenuOpen, setTeamMenuOpen] = useState(false);
  const [claimedByTeams, setClaimedByTeams] = useState();

  const [claim, setClaim] = useState();
  //row.Claim ?row.Claim : null

  useEffect(() => {
    setTeamName(row.Team);
    setTeamNames(row.ReportedByTeams && row.ReportedByTeams);
    setClaimedByTeams(row.Claimed_by_team && row.Claimed_by_team);
  }, [row.ReportedByTeams]);

  const handleAddReportedByTeam = async (item) => {
    // e.preventDefault();

    let newReportedByTeams = [...row.ReportedByTeams];
    newReportedByTeams.push(item.name);

    const updated = await _impediments.updateImpediment(
      row._id,
      null,
      "ReportedByTeams",
      newReportedByTeams,
      null
    );

    if (updated) {
      //ISSUE HERE
      // The newReportedByTeams is correct but updated.ReportedByTeams is not

      updateData(updated);
    }

    // const updated = _impediments.claimImpediment(impedimentId);
  };

  return (
    <>
      {/* <div className="whitespace-nowrap text-sm text-gray-500"> */}
      <div className="flex items-center text-slate-900 mt-1">
        <div
          className="flex items-center cursor-pointer "
          onMouseOver={() => setTeamMenuOpen(true)}
          onMouseLeave={() => setTeamMenuOpen(false)}
        >
          {/* {teamNames && teamNames.length > 0 && "Reported by "} */}

          {teamNames &&
            teamNames.length > 0 &&
            teamNames.slice(0, 1).map((team, idx) => {
              return (
                <span key={idx} className="mr-2">
                  {team}
                </span>
              );
            })}
          {teamNames && teamNames.length > 1 && (
            <span className="mr-2">+{teamNames.length - 1}</span>
          )}
          <Menu2 open={teamMenuOpen} items={teamNames} />
        </div>
        {profile && profile.tenantInfo.type == "admin" && (
          <div className="flex items-center font-normal text-gray-500">
            <Button
              iconButton
              size="xs"
              className="flex items-center"
              handleClick={setOpen}
            >
              <PlusCircleIcon onClick={setOpen} className="h-5 w-5 my-auto" />
            </Button>

            <CommandPalette
              open={open}
              setOpen={setOpen}
              options={teams}
              handleClick={(item) => handleAddReportedByTeam(item)}
            />
          </div>
        )}
      </div>
      {/* <div className="flex font-normal text-gray-500">
          {" "}
          {claim == "Claimed" ? (
            <Icon
              icon="material-symbols:workspace-premium-outline-rounded"
              className="mr-1 my-auto"
            />
          ) : (
            <Icon
              icon="material-symbols:warning-outline-rounded"
              className="mr-1 my-auto"
            />
          )}
          {claim}
        </div> */}
      {/* </div> */}
    </>
  );
}

export function RenderNewReportedByTeams({
  row,
  setReportedByTeamModalOpen,
  teams,
  handleCommandClick,
  updateData,
  profile,
}) {
  const [teamName, setTeamName] = useState();
  const [open, setOpen] = useState(false);
  const [teamNames, setTeamNames] = useState();
  const [teamMenuOpen, setTeamMenuOpen] = useState(false);
  const [claimedByTeams, setClaimedByTeams] = useState();

  const [claim, setClaim] = useState();
  //row.Claim ?row.Claim : null

  useEffect(() => {
    setTeamName(row.Team);
    setTeamNames(row.ReportedByTeams && row.ReportedByTeams);
    setClaimedByTeams(row.Claimed_by_team && row.Claimed_by_team);
  }, [row.ReportedByTeams]);

  const handleAddReportedByTeam = async (item) => {
    // e.preventDefault();

    let newReportedByTeams = [...row.ReportedByTeams];
    newReportedByTeams.push(item.name);

    const updated = await _impediments.updateImpediment(
      row._id,
      null,
      "ReportedByTeams",
      newReportedByTeams,
      null
    );

    if (updated) {
      //ISSUE HERE
      // The newReportedByTeams is correct but updated.ReportedByTeams is not

      updateData(updated);
    }

    // const updated = _impediments.claimImpediment(impedimentId);
  };

  return (
    <>
      {/* <div className="whitespace-nowrap text-sm text-gray-500"> */}
      <div className="flex items-center text-slate-900 mt-3">
        <div
          className="flex items-center cursor-pointer "
          onMouseOver={() =>
            row.teams_impacted.length > 2 && setTeamMenuOpen(true)
          }
          onMouseLeave={() => setTeamMenuOpen(false)}
        >
          {/* {teamNames && teamNames.length > 0 && "Reported by "} */}

          {row &&
            row.teams_impacted &&
            row.teams_impacted.slice(0, 2).map((team, idx) => {
              return (
                team.claimed_by_team &&
                team.claimed_by_team.tenantTitle && (
                  <span key={idx} className="mr-2">
                    {team.claimed_by_team.tenantTitle}
                  </span>
                )
              );
            })}
          {row.teams_impacted && row.teams_impacted.length > 2 && (
            <span className="mr-2 flex">
              {
                <a
                  class="flex items-center justify-center p-1 h-8 w-8 text-xs 
              font-medium text-white bg-accent-600 border-2 border-white rounded-full 
              transition-all ease-in-out hover:scale-110 duration-150"
                  href="#"
                >
                  +{row.teams_impacted.length - 2}
                </a>
              }
            </span>
          )}
          <Menu2
            open={teamMenuOpen}
            items={
              row &&
              row.teams_impacted &&
              row.teams_impacted.slice(2).map((team, idx) => {
                return (
                  team.claimed_by_team &&
                  team.claimed_by_team.tenantTitle && (
                    <span key={idx} className="mr-2">
                      {team.claimed_by_team.tenantTitle}
                    </span>
                  )
                );
              })
            }
          />
        </div>
        {/* {profile && profile.tenantInfo.type == "admin" && (
          <div className="flex items-center font-normal text-gray-500">
            <Button
              iconButton
              size="xs"
              className="flex items-center"
              handleClick={setOpen}
            >
              <PlusCircleIcon onClick={setOpen} className="h-5 w-5 my-auto" />
            </Button>

            <CommandPalette
              open={open}
              setOpen={setOpen}
              options={teams}
              handleClick={(item) => handleAddReportedByTeam(item)}
            />
          </div>
        )} */}
      </div>
      {/* <div className="flex font-normal text-gray-500">
          {" "}
          {claim == "Claimed" ? (
            <Icon
              icon="material-symbols:workspace-premium-outline-rounded"
              className="mr-1 my-auto"
            />
          ) : (
            <Icon
              icon="material-symbols:warning-outline-rounded"
              className="mr-1 my-auto"
            />
          )}
          {claim}
        </div> */}
      {/* </div> */}
    </>
  );
}

export function RenderCompetencyList({ row, competencies, updateData, from }) {
  const buttonRef = useRef(null);

  const [edit, setEdit] = useState(false);
  const [selectedCompetency, setSelectedCompetency] = useState(
    row && row.Competency && row.Competency.Name
  );
  const [selectedArea, setSelectedArea] = useState(
    row && row.Competency && row.Competency.Area
  );
  //
  // let edit = true;
  let impedimentId = row && row._id;
  //
  useEffect(() => {}, [from]);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (target === null) return;
      if (target.id === "") return;

      if (
        target.id !== "dropdown" &&
        target.id !== "editButton" &&
        target.id !== "buttonRef" &&
        target.id !== "headlessui-listbox-button-:r6v:" &&
        target.parentElement.id !== "dropdown" &&
        target.id !== "dropdown-button" &&
        target.parentElement.id !== "dropdown-button" &&
        target.parentElement.id !== "headlessui-listbox-button-:r6v:"
      ) {
        setEdit(false);
      }
    };

    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    setEdit(false);
    setSelectedCompetency(
      row && row.Competency
        ? row.Competency.Name
          ? row.Competency.Name
          : row.Competency.name
          ? row.Competency.name
          : null
        : null
    );
    setSelectedArea(
      row && row.Competency
        ? row.Competency.Area
          ? row.Competency.Area
          : row.Competency.area
          ? row.Competency.area
          : null
        : null
    );
    // setSelectedArea(row && row.Competency ? row.Competency.Area : null);
    //
    //
  }, [row && row.Competency]);

  useEffect(() => {}, [selectedCompetency, selectedArea]);

  useEffect(() => {}, [edit]);
  //PROBLEM:  NEED UPDATEDATA like in the other components
  const handleSelect = async (value, impediment, v, obj) => {
    //if the component is used from impediments, update, otherwise don't
    // setLoading(true);

    if ((from && from == "list") || from == "sidebar") {
      const updated = await _impediments.updateImpedimentCompetencyId(
        impediment,
        value,
        impedimentId
      );
      if (updated) {
        const fieldsToUpdate = [
          // { field: "Competency", value: updated.Competency },
          {
            colName: "Competency._id",
            value: updated.Competency._id,
            rowIndex: row.index,
          },
          {
            colName: "Competency.Name",
            value: updated.Competency.Name,
            rowIndex: row.index,
          },
          {
            colName: "Competency.Area",
            value: updated.Competency.Area,
            rowIndex: row.index,
          },
          // { colName: "Assigned_to", value: updated.Competency.Name },
        ];
        setEdit(false);
        updateData(updated, impedimentId, row, fieldsToUpdate);
        setSelectedArea(obj.area);
        setSelectedCompetency(obj.name);
      }
    } else {
      updateData(
        { target: { name: "Competency", value: obj.id } },
        impedimentId,
        row,
        null
      );
      setSelectedArea(obj.area);
      setSelectedCompetency(obj.name);
      setEdit(false);
    }
  };
  return (
    <div className="flex space-x-3  ">
      {edit !== true ? (
        <button
          id="editButton"
          ref={buttonRef}
          onClick={(e) => {
            e.preventDefault();
            setEdit(true);
          }}
          className={`${
            row && row.Competency
              ? "bg-accent-700 text-accent-50"
              : "bg-orange-100 text-secondary-700"
          }  px-3 py-1 text-xs rounded-md whitespace-nowrap`}
        >
          {selectedCompetency
            ? selectedArea + " - " + selectedCompetency
            : "Select a competency"}
          {/* {props.Competency
            ? props.Competency.Area 
            + " - " +
              props.Competency.Name
            : "Select a competency"} */}
        </button>
      ) : (
        <div>
          <MultiSelectListBox
            key={impedimentId}
            options={competencies}
            fieldType="single"
            label="Competencies"
            name="competencies"
            impedimentId={impedimentId}
            // handleSelect={null}
            handleDeselect={null}
            handleSelect={handleSelect}
            setEdit={setEdit}
            // handleDeselect={(e) => handleDeselect(e)}
            // defaultValue={
            //   props.Competency
            //     ? props.Competency.Name
            //     : "Select a competency"
            // }
            value={selectedCompetency ? selectedCompetency : "Select"}
          />
          {/* <div>
            {edit == true ? (
              <div className="inline">
                <Button color="link" handleClick={() => setEdit(false)}>
                  X
                </Button>
              </div>
            ) : (
              <div>not edit</div>
            )}
          </div> */}
        </div>
      )}
    </div>
  );
}

export function RenderImpedimentTypeList({ row, profile }) {
  let options = [
    {
      id: 1,
      value: "Infrastructure",
      name: "Infrastructure",
      area: "Technical",
      description: "",
      Examples: [],
    },
    {
      id: 1,
      value: "Architecture",
      name: "Architecture",
      area: "Technical",
      description: "",
      Examples: [],
    },

    {
      id: 1,
      value: "3rd Party Dependency",
      name: "3rd Party Dependency",
      area: "Technical",
      description: "",
      Examples: [],
    },

    {
      id: 2,
      value: "Insufficient Tooling",
      name: "Insufficient Tooling",
      area: "Operational",
      description: "",
      Examples: [],
    },
    {
      id: 2,
      value: "Best Practices/Standards",
      name: "Best Practices/Standards",
      area: "Operational",
      description: "",
      Examples: [],
    },
    {
      id: 2,
      value: "Unstable Environments",
      name: "Unstable Environments",
      area: "Operational",
      description: "",
      Examples: [],
    },
    {
      id: 3,
      value: "Change Management",
      name: "Change Management",
      area: "Process",
      description: "",
      Examples: [],
    },
    {
      id: 3,
      value: "Security Approvals",
      name: "Security Approvals",
      area: "Process",
      description: "",
      Examples: [],
    },
    {
      id: 4,
      value: "Team Topology",
      name: "Team Topology",
      area: "Organization",
      description: "",
      Examples: [],
    },
    {
      id: 4,
      value: "Ownership",
      name: "Ownership",
      area: "Organization",
      description: "",
      Examples: [],
    },
    {
      id: 4,
      value: "Team Dysfunction",
      name: "Team Dysfunction",
      area: "Organization",
      description: "",
      Examples: [],
    },
  ];

  const buttonRef = useRef(null);

  const [edit, setEdit] = useState(false);
  // cosnt[(selectedType, setSelectedType)] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(row && row.Category);
  const [selectedSubcategory, setSelectedSubcategory] = useState(
    row && row.Subcategory
  );
  const [disabled, setDisabled] = useState(false);
  //
  // let edit = true;
  let impedimentId = row && row._id;
  //

  useEffect(() => {
    const clickHandler = ({ target }) => {
      // if (!editButton.current || editButton.current === null) return;
      // if (editButton.current.contains(target)) return;

      if (
        target.id !== "dropdown" &&
        target.id !== "editButton" &&
        target.id !== "buttonRef" &&
        target.id !== "headlessui-listbox-button-:r6v:" &&
        target.parentElement.id !== "dropdown" &&
        target.id !== "dropdown-button" &&
        target.parentElement.id !== "dropdown-button" &&
        target.parentElement.id !== "headlessui-listbox-button-:r6v:"
      ) {
        setEdit(false);
      }
    };

    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    setDisabled(profile.tenantInfo && profile.tenantInfo.type !== "admin");
  }, []);

  useEffect(() => {
    setEdit(false);
    setSelectedCategory(row && row.Category ? row.Category : null);
    setSelectedSubcategory(row && row.Subcategory ? row.Subcategory : null);
  }, [row && row.Category]);
  //
  const handleSelect = async (id, impediment, value, objValue) => {
    let category = objValue.area;
    let subcategory = objValue.name;

    let data = {
      Subcategory: subcategory,
    };

    const updated = await _impediments.updateImpediment(
      impediment,
      null,
      "Category",
      category,
      data
    );
    if (updated) {
      setSelectedCategory(category);
      setSelectedSubcategory(subcategory);
    }
  };
  return (
    <div className="flex items-center space-x-3">
      {!edit ? (
        <button
          disabled={disabled}
          id="editButton"
          ref={buttonRef}
          onClick={(e) => {
            e.preventDefault();
            setEdit(true);
          }}
          className={`
          ${
            disabled
              ? "bg-gray-100 text-gray-600 cursor-not-allowed"
              : selectedCategory
              ? "bg-accent-700 text-accent-50 cursor-pointer"
              : "bg-orange-100 text-secondary-700 cursor-pointer"
          }  px-3 py-1 text-xs rounded-md whitespace-nowrap`}
          // className="bg-accent-600 text-accent-100 px-3 py-1 text-xs rounded-md whitespace-nowrap "
        >
          {selectedCategory && selectedSubcategory
            ? selectedCategory + " - " + selectedSubcategory
            : "Select Impediment Type"}
        </button>
      ) : (
        <MultiSelectListBox
          key={impedimentId}
          options={options.sort((a, b) => a.area.localeCompare(b.area))}
          fieldType="single"
          label="Categories"
          name="categories"
          impedimentId={impedimentId}
          // handleSelect={null}
          handleDeselect={null}
          handleSelect={handleSelect}
          // handleDeselect={(e) => handleDeselect(e)}
          // defaultValue={
          //   props.Competency
          //     ? props.Competency.Name
          //     : "Select a competency"
          // }
          value={row.Subcategory ? row.Subcategory : "Select"}
        />
      )}
    </div>
  );
}
export function RenderScopeList({ row, profile }) {
  let options = [
    "Team",
    "Multi-Team",
    "Domain",
    "Cross-Domain",
    "Department",
    "Cross-Department",
  ];

  const buttonRef = useRef(null);

  const [edit, setEdit] = useState(false);
  // cosnt[(selectedType, setSelectedType)] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(row && row.Scope);
  const [disabled, setDisabled] = useState(false);

  //
  // let edit = true;
  let impedimentId = row && row._id;
  //
  useEffect(() => {
    const clickHandler = ({ target }) => {
      // if (target === null) return;
      // if (target.id === "") return;
      if (
        target === null ||
        target.id === "" ||
        (target.id !== "dropdown" &&
          target.id !== "editButton" &&
          target.id !== "buttonRef" &&
          target.id !== "headlessui-listbox-button-:r6v:" &&
          target.parentElement.id !== "dropdown" &&
          target.id !== "dropdown-button" &&
          target.parentElement.id !== "dropdown-button" &&
          target.parentElement.id !== "headlessui-listbox-button-:r6v:")
      ) {
        setEdit(false);
      }
    };

    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    setDisabled(profile.tenantInfo && profile.tenantInfo.type !== "admin");
  }, [profile]);

  useEffect(() => {
    setEdit(false);
    setSelectedCategory(row && row.Scope ? row.Scope : null);
  }, [row && row.Scope]);
  //
  const handleSelect = async (id, impediment, value, objValue) => {
    let scope = value;

    let data = {
      Scope: scope,
    };

    const updated = await _impediments.updateImpediment(
      impediment,
      null,
      "Scope",
      scope,
      data
    );
    if (updated) {
      setSelectedCategory(scope);
    }
  };
  return (
    <div className="flex items-center space-x-3">
      {!edit ? (
        <button
          disabled={disabled}
          id="editButton"
          ref={buttonRef}
          onClick={(e) => {
            e.preventDefault();
            setEdit(true);
          }}
          className={`
          ${
            disabled
              ? "bg-gray-100 text-gray-600 cursor-not-allowed"
              : selectedCategory
              ? "bg-accent-700 text-accent-50 cursor-pointer"
              : "bg-orange-100 text-secondary-700 cursor-pointer"
          }  px-3 py-1 text-xs rounded-md whitespace-nowrap`}
        >
          {selectedCategory ? selectedCategory : "Select Impediment Scope"}{" "}
        </button>
      ) : (
        <MultiSelectListBox
          key={impedimentId}
          options={
            typeof options[0] === "object"
              ? options.sort((a, b) => a.area.localeCompare(b.area))
              : options.sort((a, b) => a.localeCompare(b))
          }
          fieldType="single"
          label="Scope"
          name="scope"
          impedimentId={impedimentId}
          // handleSelect={null}
          handleDeselect={null}
          handleSelect={handleSelect}
          // handleDeselect={(e) => handleDeselect(e)}
          // defaultValue={
          //   row.Competency
          //     ? row.Competency.Name
          //     : "Select a competency"
          // }
          value={row.Scope ? row.Scope : "Select"}
        />
      )}
    </div>
  );
}

export function RenderNumberColumn({
  row,
  handleTextColumnValueChange2,
  handleOnBlur,
  columnId,
  profile,
}) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [value, setValue] = useState("");
  const [data, setData] = useState({});
  const [showFields, setShowFields] = useState(false);

  useEffect(() => {
    setData(row);
  }, [row]);

  // useEffect(() => {

  // }, [data]);

  useEffect(() => {
    //find claimed_by_team by profile.tenant_id and set to state
    let v = "";
    try {
      if (row.teams_impacted && row.teams_impacted.length > 0) {
        v =
          row.teams_impacted[
            row.teams_impacted.findIndex(
              (el) => el.claimed_by_team._id === profile.tenantId
            )
          ][columnId];

        if (v === undefined) v = "";
      } else {
        v = "";
      }
    } catch (err) {
      v = "";
    }

    setValue(v);
  }, [row]);

  useEffect(() => {
    const team =
      row &&
      row.teams_impacted &&
      row.teams_impacted[
        row.teams_impacted.findIndex(
          (el) =>
            el.claimed_by_team &&
            el.claimed_by_team._id &&
            el.claimed_by_team._id === profile.tenantId
        )
      ];
    if (team) {
      setShowFields(true);
    } else {
      setShowFields(false);
    }
  }, [row]);

  const handleNumberColumnonBlur = (e, id, columnId, row) => {
    // let value = e.target.value;
    const valid = checkValid(e.target.value);
    if (valid) {
      handleOnBlur(e, id, columnId, data);
    } else {
      setError(true);
      setErrorMessage("Please enter a number greater than 0 or leave blank");
      return;
    }
  };

  const handleNumberColumnChange = (e, id, columnId, row) => {
    setError(false);
    setErrorMessage("");
    // let value = e.target.value;
    const valid = checkValid(value);
    let newData = data;
    if (valid) {
      setValue(e.target.value);
      if (newData.teams_impacted) {
        let index = newData.teams_impacted.findIndex(
          (el) => el.claimed_by_team._id === profile.tenantId
        );

        newData.teams_impacted[
          newData.teams_impacted.findIndex(
            (el) => el.claimed_by_team._id === profile.tenantId
          )
        ][columnId] = Number(e.target.value);

        setData(newData);
      }
    } else {
      setError(true);
      setErrorMessage("Please enter a number greater than 0 or leave blank");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, 500);
    }
  };

  const checkValid = (value) => {
    if (
      //value not a number
      isNaN(value) ||
      //value is a number but not an integer
      (value % 1 != 0 && value != "") ||
      //value is a number but is negative
      (value < 0 && value != "")
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="w-24 flex items-center">
      <input
        className={`w-full  h-6 text-sm px-3  
        border border-gray-200
        rounded focus:ring-2 
        transition-all ease-in-out hover:scale-150 duration-150
        ${
          error
            ? "focus:ring-red-500 focus:outline-none focus:border-red-500   transition duration-150 ease-in-out"
            : "focus:ring-secondary-500 focus:outline-none focus:border-secondary-500"
        } focus:shadow-outline`}
        disabled={showFields == false}
        placeholder={
          showFields == false ? (columnId == "score" ? "hrs/wk" : "days") : ""
        }
        value={value}
        onChange={(e) => handleNumberColumnChange(e, row._id, columnId, row)}
        onBlur={(e) =>
          handleNumberColumnonBlur(e, row._id, "teams_impacted", row)
        }
      />
    </div>
  );
}

export function RenderNumberColumnV2({
  row,
  handleTextColumnValueChange2,
  handleOnBlur,
  columnId,
}) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  const handleNumberColumnonBlur = (e, id, columnId, row) => {
    let value = e.target.value;
    const valid = checkValid(value);
    if (valid) {
      handleOnBlur(e, id, columnId, row);
    } else {
      setError(true);
      setErrorMessage("Please enter a number greater than 0 or leave blank");
      return;
    }
  };

  const handleNumberColumnChange = (e, id, columnId, row) => {
    setError(false);
    setErrorMessage("");
    let value = e.target.value;
    const valid = checkValid(value);
    if (valid) {
      handleTextColumnValueChange2(e, id, columnId, row);
    } else {
      setError(true);
      setErrorMessage("Please enter a number greater than 0 or leave blank");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, 500);
    }
  };

  const checkValid = (value) => {
    if (
      //value not a number
      isNaN(value) ||
      //value is a number but not an integer
      (value % 1 != 0 && value != "") ||
      //value is a number but is negative
      (value < 0 && value != "")
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="w-24 flex items-center">
      <input
        className={`w-full  h-6 text-sm px-3  
        rounded focus:ring-2 
        transition-all ease-in-out hover:scale-150 duration-150
        ${
          error
            ? "focus:ring-red-500 focus:outline-none focus:border-red-500   transition duration-150 ease-in-out"
            : "focus:ring-secondary-500 focus:outline-none focus:border-secondary-500"
        } border border-gray-200 focus:shadow-outline`}
        value={row[columnId]}
        onChange={(e) => handleNumberColumnChange(e, row._id, columnId, row)}
        onBlur={(e) => handleNumberColumnonBlur(e, row._id, columnId)}
      />
      {/* <span className="-ml-10 text-gray-500 ">
        {props.column.id == "score" ? " hrs/wk " : "days"}
      </span> */}
    </div>
  );
}

export function RenderHeader(props, name, sub, description) {
  return (
    <div className="flex text-center space-x-2 ">
      <div>
        {name}
        <div className="text-xs text-accent-700">{sub}</div>
      </div>
      <Tooltip
        className={" text-xs text-slate-500"}
        position={"bottom"}
        size="md"
        description={description}
        title={name}
      >
        <QuestionMarkCircleIcon className="h-4 w-4 align-top text-slate-400 mb-auto" />
      </Tooltip>
    </div>
  );
}

export function RenderSubComponent({ handleOnBlur, row, field }) {
  const [updatedDescription, setUpdatedDescription] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setDescription(row[field]);
  }, [row[field]]);
  useEffect(() => {
    setUpdatedDescription(row[field]);
  }, [row[field]]);

  return (
    <div className="relative block w-full text-gray-800">
      <div className="pt-1.5 items-start text-xl pointer-events-none absolute inset-y-0 left-0 flex pl-3 mr-3 pr-3">
        <ClipboardDocumentListIcon
          className="h-6 w-6 text-gray-500"
          aria-hidden="true"
        />
      </div>
      <textarea
        rows={4}
        type="text"
        name="description"
        className="w-full inline-flex resize-none prose h-fit text-sm px-10  rounded border border-1 border-gray-200 focus:outline-none focus:ring-1 focus:ring-secondary-500 focus:border-secondary-500 focus:shadow-outline "
        value={updatedDescription}
        defaultValue={description}
        onChange={(e) => setUpdatedDescription(e.target.value)}
        onBlur={(e) => handleOnBlur(e, row._id, field, row)}
      />
    </div>
  );
}

export function RenderLocalDescriptionField({
  handleOnBlur,
  row,
  field,
  profile,
}) {
  const [updatedDescription, setUpdatedDescription] = useState();
  const [description, setDescription] = useState();
  const [myTeam, setMyTeam] = useState();

  useEffect(() => {
    const myTeam =
      row.teams_impacted &&
      row.teams_impacted.find(
        (el) => el.claimed_by_team._id === profile.tenantId
      );

    setMyTeam(myTeam);
  }, [row]);

  useEffect(() => {
    //scope to local description

    setDescription(myTeam && myTeam[field] ? myTeam[field] : "");
    // setDescription(row[field]);
  }, [myTeam]);

  useEffect(() => {
    setUpdatedDescription(myTeam && myTeam[field] ? myTeam[field] : "");
    // setUpdatedDescription(row[field]);
  }, [myTeam]);

  return (
    <div className="relative block w-full text-gray-800">
      <div className="pt-1.5 items-start text-xl pointer-events-none absolute inset-y-0 left-0 flex pl-3 mr-3 pr-3">
        <ClipboardDocumentListIcon
          className="h-6 w-6 text-gray-500"
          aria-hidden="true"
        />
      </div>
      <textarea
        rows={4}
        type="text"
        name="description"
        placeholder="Write a few lines about how you hope to tackle this problem"
        className="w-full inline-flex resize-none prose h-fit text-sm px-10  rounded border border-1 border-gray-200 focus:outline-none focus:ring-1 focus:ring-secondary-500 focus:border-secondary-500 focus:shadow-outline "
        value={updatedDescription}
        defaultValue={description}
        onChange={(e) => setUpdatedDescription(e.target.value)}
        onBlur={(e) => handleOnBlur(e, row._id, field, row)}
      />
    </div>
  );
}

export function RenderTextField({
  handleOnBlur,
  row,
  field,
  placeholder,
  data,
}) {
  const [updatedDescription, setUpdatedDescription] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setDescription(row[field]);
  }, [row, data]);

  useEffect(() => {
    setUpdatedDescription(row[field]);
  }, [row, data]);

  return (
    <div className="block  w-full    ">
      <input
        type="text"
        name="title"
        className="w-full flex grow  h-fit text-base  rounded 
        py-0 px-2
        border-none 
        focus:outline-none focus:ring-1 focus:ring-secondary-500 focus:border-secondary-500 focus:shadow-outline "
        value={updatedDescription}
        // defaultValue={description}
        onChange={(e) => setUpdatedDescription(e.target.value)}
        onBlur={(e) => handleOnBlur(e, row._id, field)}
        placeholder={placeholder}
      />
    </div>
  );
}

export function RenderTagCell(props) {
  const [value, setValue] = useState(props.Resolved == true ? true : false);
  useEffect(() => {
    setValue(props.Resolved == true ? true : false);
  }, [props.Resolved]);

  return value == true ? (
    <span className="flex bg-green-100 text-green-700 px-3 py-1   text-xs rounded-md ">
      <Icon icon="ic:round-check" className="mr-1 my-auto" /> Resolved
    </span>
  ) : (
    <span className="flex bg-red-100 text-red-700 px-3 py-1  text-xs rounded-md ">
      <Icon
        className="mr-1 my-auto"
        icon="material-symbols:info-outline-rounded"
      />
      Unresolved
    </span>
  );
}

export function RenderTagCell2(props) {
  const [value, setValue] = useState(props.R_and_D_Category);
  useEffect(() => {
    setValue(props.R_and_D_Category);
  }, [props.R_and_D_Category]);

  return (
    value !== "" &&
    value !== null &&
    value !== undefined && (
      <span className="bg-accent-300 text-accent-700 px-3 py-1 text-xs rounded-md">
        {value}
      </span>
    )
  );
}

function Impediments(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [data, setData] = useState([]);
  const [UnresolvedImpediments, setUnResolvedImpediments] = useState([]);
  const [ResolvedImpediments, setResolvedImpediments] = useState([]);
  const [unClaimedImpediments, setUnClaimedImpediments] = useState([]);
  const [claimedImpediments, setClaimedImpediments] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [competencyList, setCompetencyList] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const [loadingClaimButton, setLoadingClaimButton] = useState({});
  const [changed, setChanged] = useState();
  const [edit, setEdit] = useState(true);
  const [editRow, setEditRow] = useState({});
  const [description, setDescription] = useState([]);
  const [title, setTitle] = useState([]);
  const [updateField, setUpdateField] = useState([]);
  const [updatedId, setUpdatedId] = useState("");
  const [teams, setTeams] = useState([]);
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem("View all impediments") == null || undefined || ""
      ? "All impediments"
      : localStorage.getItem("View all impediments")
  );
  const [toggleValue, setToggleValue] = useState(
    localStorage.getItem("View all impediments") == null || undefined || ""
      ? true
      : localStorage.getItem("View all impediments") == "All impediments"
      ? true
      : false
  );

  const [searchValue, setSearchValue] = useState("");
  const [searchReportedByValue, setSearchReportedByValue] = useState("");
  const [reportedByTeamModalOpen, setReportedByTeamModalOpen] = useState(false);
  const [impedimentModalOpen, setImpedimentModalOpen] = useState(false);
  const history = useHistory();
  const profile = _auth.getUserProfile();
  const [exportImpedimentsLoading, setExportImpedimentsLoading] =
    useState(false);
  const [dedupeMode, setDedupeMode] = useState(
    true
    // profile.tenantInfo.type !== "admin"
    //   ? false
    //   : localStorage.getItem("Dedupe") == null || undefined || ""
    //   ? false
    //   : localStorage.getItem("Dedupe") == "true"
    //   ? true
    //   : false
  );
  const limit = employees.length > 50 ? 50 : employees.length;
  // const [columns, setColumns] = useState([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      setLoading(true);
      // const impediments = await _impediments.getImpediments();
      // if (impediments) {
      //   let filtered = impediments.filter((impediment) => {
      //     return (impediment.Claimed_by_team._id = profile.tenantId);
      //   });
      //   if (!filtered) {
      //     setToggleValue(false);
      //   }
      //   if (toggleValue === false) {
      //     setEmployees(impediments);
      //     setInitialValues(impediments);
      //     setList(impediments);
      //     setLoading(false);
      //   } else {
      //     let filtered = impediments.filter((impediment) => {
      //       return (impediment.Claimed_by_team._id = profile.tenantId);
      //     });
      //     setEmployees(filtered);
      //     setInitialValues(filtered);
      //     setList(filtered);
      //     setLoading(false);
      //   }
      // } else {
      //   setLoading(false);
      // }

      let impedimentList = [];
      if (props && props.impediments) {
        setEmployees(props.impediments);
        setData(props.impediments);
        setInitialValues(props.impediments);
        setList(props.impediments);
        setLoading(false);
      } else {
        if (toggleValue === true) {
          impedimentList = await _impediments.getImpediments(true);
        } else {
          impedimentList = await _impediments.getImpediments();
        }
        if (impedimentList) {
          setEmployees(impedimentList);
          setData(impedimentList);
          setInitialValues(impedimentList);
          setList(impedimentList);
          setSelectedRow(impedimentList[0]);
          setLoading(false);
        }
      }
    })();
  }, [props.impediments, toggleValue]);

  useEffect(() => {}, [selectedRow]);

  useEffect(() => {
    (async () => {
      const teams = await _teams.getTeamNicknames();

      if (teams) {
        //set name to nickname
        // set id to _id
        teams.forEach((team) => {
          team.name = team["Nickname"]
            ? team["Nickname"] !== ""
              ? team["Nickname"]
              : team["Team Name"]
            : team["Team Name"];
          team.id = team._id;
          team.url = "#";
        });
        //filter out null
        // teams.filter((team) => {
        //   return team.Nickname !== null;
        // });

        setTeams(teams);
      }
    })();
  }, [limit]);

  // useMemo(() => {
  //   (async () => {

  //     const teams = await _teams.getTeams();

  //     if (teams) {
  //       setTeams(teams);
  //     }
  //   })();
  // }, [limit]);

  // useEffect(() => {

  //   if (toggleValue === false) {
  //     setUnClaimedImpediments(employees);
  //   } else {
  //     let filtered = employees.filter((employee) => {
  //       return employee.Claimed !== true;
  //     });
  //     setUnClaimedImpediments(filtered);
  //   }
  // }, [employees, toggleValue]);
  useEffect(() => {
    //execute search 1 after 1 second
    if (searchValue.length < 3) {
      setData(employees);
      return;
    }
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      searchValue.length > 2
        ? setData(
            employees.filter((item) =>
              item.Description.toLowerCase().includes(searchValue.toLowerCase())
            )
          )
        : setData(employees);
    }, 300);
  }, [searchValue]);

  useEffect(() => {
    //execute search 1 after 1 second
    if (searchReportedByValue.length < 3) {
      setData(employees);
      return;
    }

    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (searchReportedByValue.length > 2) {
        let f1 = [];

        employees.map((item) => {
          item.ReportedByTeams.filter((team) => {
            if (
              team.toLowerCase().includes(searchReportedByValue.toLowerCase())
            ) {
              f1.push(item);
            }
          });
        });

        setData(f1);
      } else {
        setData(employees);
      }

      // searchReportedByValue.length > 2
      //   ? let filtered = employees.filter((item) =>
      //       item.ReportedByTeams.filter((team) =>
      //         team.toLowerCase().includes(searchReportedByValue.toLowerCase())
      //       ))

      //   setData(
      //       filtered
      //     )
      //   : setData(employees);
    }, 1000);
  }, [searchReportedByValue]);

  // useEffect(() => {
  //   setData((prev) => {
  //     if (prev !== employees) {
  //       setData(prev);
  //     }
  //   });
  // }, [employees]);

  useEffect(() => {
    (async () => {
      let compList = await _competencies.getCompetencyList();
      if (compList) {
        setCompetencies(compList);
      } else {
      }
    })();
  }, [list]);

  const exportToExcel = async (data, name) => {
    setExportImpedimentsLoading(true);
    const done = await exports.exportToExcel(name);
    setExportImpedimentsLoading(false);
  };

  // const FilterOptions = React.useMemo(() => {
  //   const options = new Set();
  //   options.add("Filter by " + "Email"); //render("Header")
  //   let added = [];
  //   if (employees === "loading") return options;
  //   if (employees.length === 0) return options;
  //   employees.forEach((row, index) => {

  //     if (row.userId !== undefined) {
  //       if (!added.includes(row.userId)) {
  //         added.push(row.userId);
  //         options.add(row.email);
  //       }
  //     }
  //   });

  //   return [...options.values()];
  // }, [data]);

  const handleClearAllSelections = (field) => {
    if (field === "Description") {
      setSearchValue("");
      setData(employees);
    }
    if (field === "ReportedBy") {
      setSearchReportedByValue("");
      setData(employees);
    }
  };

  const handleSearchList = (e, field) => {
    if (field === "Description") {
      setSearchValue(e.target.value);
    }
    if (field === "ReportedBy") {
      setSearchReportedByValue(e.target.value);
    }
  };

  const skipResetRef = React.useRef(false);

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    skipResetRef.current = true;
    setEmployees((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  // React.useEffect(() => {
  //   skipResetRef.current = false;
  // }, [employees]);

  useEffect(() => {}, [employees]);

  const handleCommandClick = (item) => {
    let updated = _impediments.updateImpediment({});
  };

  const handleSetDedupeMode = () => {
    localStorage.setItem("Dedupe", !dedupeMode);
    setDedupeMode(!dedupeMode);
  };

  const updateData = (
    updated,
    impedimentId,
    rowProps,
    fieldsToUpdate,
    action
  ) => {
    const descriptionFilter = searchValue;
    const reportedByFilter = searchReportedByValue;

    if (action === "delete") {
      let newState = employees.filter((impediment) => {
        return impediment._id !== updated._id;
      });
      let newData = data.filter((impediment) => {
        return impediment._id !== updated._id;
      });

      if (reportedByFilter == "" || descriptionFilter == "") {
        setEmployees(newState);
        setData(newData); //this is the UI data
        setInitialValues(newState);
        setList(newState);
        setSelectedRow(updated);
        // setSearchReportedByValue(reportedByFilter);
        // setSearchValue(descriptionFilter);
      } else {
        setEmployees(newState);
        setData(newData); //this is the UI data
        setInitialValues(newState);
        setList(newState);
        setSelectedRow(updated);
        // setSearchReportedByValue(reportedByFilter);
        // setSearchValue(descriptionFilter);
      }
    } else {
      let old = employees.map((row, index) => {
        if (row._id === updated._id) {
          return {
            ...row,
            ...updated,
          };
        }
        return row;
      });
      let oldData = data.map((row, index) => {
        if (row._id === updated._id) {
          return {
            ...row,
            ...updated,
          };
        }
        return row;
      });

      setEmployees(old);
      setData(oldData);
      setInitialValues(old);
      setList(old);
      setSearchReportedByValue(reportedByFilter);
      setSearchValue(descriptionFilter);
      setSelectedRow(updated);
    }
    //   setEmployees((old) =>
    //     old.map((row, index) => {
    //       if (row._id === updated._id) {

    //         return {
    //           ...row,
    //           ...updated,
    //         };
    //       }
    //       return row;
    //     }
    //     )
    //   );
    // }
  };

  // }

  // const updateData = (updated, impedimentId, rowProps, fieldsToUpdate) => {
  //   //

  //   fieldsToUpdate.forEach((field) => {
  //
  //     let rowIndex = field.rowIndex;
  //     let columnId = field.colName;
  //     let subfield;
  //     //if columnId has a period, split it up into an array
  //     if (columnId.includes(".")) {
  //       columnId = columnId.split(".");
  //       subfield = columnId[1];
  //       columnId = columnId[0];
  //     }

  //     let value = field.value;
  //     skipResetRef.current = true;
  //     setEmployees((old) =>
  //       old.map((row, index) => {
  //         if (index === rowIndex) {
  //

  //           if (subfield) {

  //             return {
  //               ...row,
  //               [columnId]: {
  //                 ...row[columnId],
  //                 [subfield]: value,
  //               },
  //             };
  //           } else {
  //             return {
  //               ...row,
  //               [columnId]: value,
  //             };
  //           }
  //         }

  //         return row;
  //       })
  //     );
  //   });

  //   // }
  // };

  const updateImpediments = async () => {
    let updatedImpediments = await _impediments.getImpediments();
    if (updatedImpediments) {
      setEmployees(updatedImpediments);
      setInitialValues(updatedImpediments);
      setList(updatedImpediments);
      setLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Description",
      //   accessor: "Description",

      //   width: 50,
      //   maxWidth: 50,

      // },
      {
        ID: "Status",
        Header: "Info",
        // (props) =>
        //   RenderHeader(
        //     props,
        //     "Status",
        //     null,
        //     "Status is the current state of the impediment."
        //   ),
        accessor: "Resolved",

        Cell: (props) => RenderInfoColumn(props),
        minWidth: "110px",
        maxWidth: "110px",
      },
      {
        ID: "Team",
        Header: "Team",
        accessor: "Team",
        // accessorFn: (row) => `${row.Team} ${row.Claimed_by_team.name}`,
        // Filter: SelectColumnFilter, // new
        // filter: "includes", // new
        type: "text",
        minWidth: "150px",
        maxWidth: "150px",

        Cell: (props) => RenderTeamName(props),
      },

      {
        ID: "Related Competency",
        Header: "Related Competency",
        accessor: "Competency.Name",
        Cell: (props) => RenderCompetencyList(props, competencies, updateData),
        // Filter: SelectColumnFilter,
        filter: "equals", // new
        type: "custom",
      },
      {
        ID: "Root Issue",
        Header: (props) =>
          RenderHeader(
            props,
            "Root Issue",
            null,
            "The root issue is the underlying cause of the impediment. It is the problem that needs to be solved to remove the impediment."
          ),
        accessor: "Subcategory",
        // Filter: SelectColumnFilter, // new
        // filter: "includes", // new
        type: "text",
        // minWidth: "150px",
        // maxWidth: "150px",
        Cell: (props) => RenderImpedimentTypeList(props, profile),
      },
      {
        ID: "Scope",
        Header: "Scope",
        accessor: "Scope",
        Cell: (props) => RenderScopeList(props, profile),
        // Filter: SelectColumnFilter,
        filter: "equals", // new
        type: "custom",
      },

      // {
      //   ID: "Category",
      //   Header: (props) =>
      //     RenderHeader(
      //       props,
      //       "Category",
      //       null,
      //       "This value is based on the DevOps model and is dynamically generated based on the selected competency."
      //     ),
      //   accessor: "R_and_D_Category",
      //   type: "text",
      //   minWidth: "150px",
      //   maxWidth: "150px",
      //   Cell: (props) => RenderDynamicRDCell(props, updateData),
      // },

      {
        ID: "Burden",
        Header: (props) =>
          RenderHeader(
            props,
            "Burden",
            "hrs/wk",
            "Burden should be expressed in hours per week wasted due to the impediment."
          ),
        accessor: "score" || "0",
        Cell: (props) =>
          RenderNumberColumn(props, handleTextColumnValueChange2, handleOnBlur),
        minWidth: "130px",
        maxWidth: "130px",
      },

      {
        ID: "Effort",
        Header: (props) =>
          RenderHeader(
            props,
            "Effort",
            "days",
            "Effort is expressed in days of work required to resolve the impediment."
          ),
        accessor: "effort" || "0",

        Cell: (props) =>
          RenderNumberColumn(props, handleTextColumnValueChange2, handleOnBlur),
        minWidth: "130px",
        maxWidth: "130px",
      },

      {
        ID: "Actions",
        Header: (props) => {
          let rendered = (
            <div className=" space-y-3">
              <div className="flex justify-start">
                <div className="text-slate-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="w-4 h-4 "
                    viewBox="0 0 20 20"
                  >
                    <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                  </svg>
                </div>
                <div className="ml-2 text-slate-500">Claim</div>
              </div>
              <div className="flex justify-start">
                <div className="text-slate-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="flex w-5 h-5 "
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-2 text-slate-500">Release</div>
              </div>
              <div className="flex justify-start">
                <div className="text-slate-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div className="ml-2 text-slate-500">Resolve</div>
              </div>
              <div className="flex justify-start">
                <div className="text-slate-500">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    className="w-4 h-4"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div className="ml-2 text-slate-500">Details</div>
              </div>
            </div>
          );
          return RenderHeader(props, "Actions", null, rendered);
        },

        accessor: "Actions",
        Cell: (props) => (
          <div className="flex justify-start pr-6">
            {RenderActions(props, updateData, setUpdatedId)}
          </div>
        ),
        // Cell: (props) => RenderActions(props, updateData),
        type: "custom",
        minWidth: 10,
        maxWidth: 10,
        sticky: true,
        position: "left",
      },
    ],
    [competencies]
  );

  const onToggleChange = async (value) => {
    setSelectedTab(value);
    localStorage.setItem("View all impediments", value);
    let tv = value === "All impediments" ? true : false;
    setToggleValue(tv);
  };

  const handleTextColumnValueChange = (e, id, fieldname) => {
    let newImpediments = [...employees];
    let index = newImpediments.findIndex((impediment) => impediment._id === id);
    //check if score field exists in employees state

    if (
      newImpediments &&
      newImpediments[index] &&
      fieldname in newImpediments[index]
    ) {
      newImpediments[index][fieldname] = e.target.value;
      setEmployees(newImpediments);
    }
  };

  const handleTextColumnValueChange2 = (e, id, fieldname, row) => {
    //update description field in state
    //setDescription with id, value

    //push updatedRow to updateField state
    // setUpdateField({ ...updateField, [id]: updatedRow });

    if (fieldname !== "Description") {
      let updatedRow = row;
      updatedRow[fieldname] = e.target.value;
      // ({ ...updateField, [id]: { [fieldname]: e.targsetUpdateFieldet.value } });
      setUpdateField({ ...updateField, [id]: updatedRow });
    } else {
      setDescription({ ...description, [id]: e.target.value });
    }
  };

  useEffect(() => {}, [updateField]);

  useEffect(() => {
    //loading changed
  }, [loading]);

  const handleOnBlur = async (e, id, fieldname) => {
    //get value from description state
    const descriptionFilter = searchValue;
    const reportedByFilter = searchReportedByValue;

    const value = e.target.value;
    //"description" ? description[id] : updateField[id][fieldname];

    //update impediment in db
    const update = await _impediments.updateImpediment(
      id,
      null,
      fieldname,
      // e.target.value
      value
    );

    if (update) {
      // const newDescription = { ...description };
      // delete newDescription[id];

      let newImpediments = [...employees];
      let index = newImpediments.findIndex(
        (impediment) => impediment._id === id
      );
      newImpediments[index][fieldname] = value;
      setEmployees(newImpediments);
      // setData(newImpediments);
      setSearchReportedByValue(reportedByFilter);
      setSearchValue(descriptionFilter);
    }
  };

  // const handleOnBlur = async (e, id, fieldname) => {

  //   //get value from description state
  //   const value = e.target.value;
  //   //"description" ? description[id] : updateField[id][fieldname];

  //   //update impediment in db
  //   const update = await _impediments.updateImpediment(
  //     id,
  //     null,
  //     fieldname,
  //     // e.target.value
  //     value
  //   );

  //   if (update) {
  //     const newDescription = { ...description };
  //     delete newDescription[id];

  //     let newImpediments = [...employees];
  //     let index = newImpediments.findIndex(
  //       (impediment) => impediment._id === id
  //     );
  //     newImpediments[index][fieldname] = value;
  //     // newImpediments[index][fieldname] = value;
  //     // setEmployees(newImpediments);
  //   }
  // };

  const handleOnBlurV2 = async (e, id, fieldname, row) => {
    //
    //get value from description state
    // const value = e.target.value;
    const value = row.teams_impacted;
    //"description" ? description[id] : updateField[id][fieldname];

    //update impediment in db
    const update = await _impediments.updateImpediment(
      id,
      null,
      "teams_impacted",
      value
    );

    if (update) {
      const newDescription = { ...description };
      delete newDescription[id];

      let newImpediments = [...employees];

      let index = newImpediments.findIndex(
        (impediment) => impediment._id === id
      );

      newImpediments[index][fieldname] = value;
      // newImpediments[index][fieldname] = value;
      // setEmployees(newImpediments);
    }
  };

  const onFieldChange = (row) => {
    let newState = [...employees];

    const impedimentIndex = employees.findIndex((el) => el._id === row._id);

    const teamIndex = newState[impedimentIndex].teams_impacted.findIndex(
      (el) => el.claimed_by_team._id === profile.tenantId
    );

    if (teamIndex > -1) {
      newState[impedimentIndex].teams_impacted[teamIndex].score = row.score;
      newState[impedimentIndex].teams_impacted[teamIndex].effort = row.effort;
    }
  };

  // const onFieldChange = (id, field, e, key, service, path) => {
  //

  //   const isChecked =
  //     e && e.target && e.target.type && e.target.type === "checkbox";
  //   const value = isChecked
  //     ? e.target.checked
  //     : e.target && e.target.value
  //     ? e.target.value
  //     : e;
  //   key = key && key.key ? key.key : key;

  //   const impedimentIndex = employees.findIndex((el) => el._id === id);

  //   const newState = { ...employees };

  //   //get path to object in state that needs to be updated
  //   //if path is null, then update the field directly
  //   if (path && path !== null) {
  //     let opts2 = (newState[impedimentIndex].teams_impacted[
  //       newState[impedimentIndex].teams_impacted.findIndex(
  //         (el) => el.claimed_by_team._id === profile.tenantId
  //       )
  //     ]["score"] = e);

  //     // //update state with newState with opts2
  //     // newState[impedimentIndex].teams_impacted[
  //     //   newState[impedimentIndex].teams_impacted.findIndex(
  //     //     (el) => el.claimed_by_team._id === profile.tenantId
  //     //   )
  //     // ] = opts2;

  //     //

  //     // debugger;
  //     setEmployees(newState);
  //   }
  // };

  const RenderTeamColumn = (row) => {
    if (
      row.Claimed_by_team &&
      row.Claimed_by_team.name !== "JDO" &&
      row.Claimed_by_team !== ""
    ) {
      return (
        <div className="flex items-center space-x-3">
          <span>{row.Claimed_by_team.name}</span>
        </div>
      );
    } else {
      return (
        <div className="flex items-center space-x-3">
          <span>{row.Team}</span>
        </div>
      );
    }
  };

  //get employees from api

  const viewDetails = (row, list) => {
    //
    let state = { data: row, list: list };
    let path = "/employees/profile";
    return history.push({
      pathname: path,
      state: state,
    });
  };

  const setChangedDateTime = () => {
    let date = new Date();
    let dateStr = date.toISOString();
    setChanged(dateStr);
    return dateStr;
  };

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const getData = () => {
    const data = employees;

    return data;
  };

  return (
    <>
      <div className="w-full max-w-10xl space-y-2">
        {/*  */}
        <div
          style={{ height: "calc(100vh - 11rem)" }}
          className="bg-white  relative  pt-3"
        >
          <div className="px-6 h-24 border-b border-gray-200 space-y-2 pb-2 mb-0">
            <div className="sm:flex sm:justify-between items-center ">
              <h2 className="text-xl flex-1 font-medium text-slate-800">
                {toggleValue ? "Company " : "My"} Impediments
              </h2>

              <div
                id="right-grid"
                className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"
              >
                {/* <ToggleButtons
                  value={toggleValue}
                  text1="My Team"
                  text2="Company"
                  onToggleChange={(value) => onToggleChange(value)}
                /> */}
                <Button
                  loader={exportImpedimentsLoading}
                  color="accent-text"
                  handleClick={() => exportToExcel(employees, "Teams")}
                  className="ml-2"
                >
                  {exportImpedimentsLoading == false && (
                    <ArrowDownCircleIcon className="h-5 w-5" />
                  )}
                  <span className="hidden xs:block ml-2">
                    Export Impediments
                  </span>
                </Button>
                <FeatureFlag featureName="Add Impediment" type="flag">
                  <Button
                    color="secondary"
                    size="sm"
                    // shadow={true}
                    handleClick={() => setImpedimentModalOpen(true)}
                    // className="btn bg-primary-500 hover:bg-primary-600 text-white"
                  >
                    <DocumentPlusIcon className="h-5 w-5" />
                    <span className="hidden xs:block ml-2">New Impediment</span>
                  </Button>
                </FeatureFlag>

                {/* <FeatureFlag featureName="Import Impediments" type="flag"> */}
                {/* <Button
                size="xs"
                color="primary"
                handleClick={(e) => {
                  
                }}
              >
                <DocumentPlusIcon className="h-5 w-5" />
                <span className="hidden xs:block ml-2">Import Impediments</span>
              </Button> */}
                {/* </FeatureFlag>
              <FeatureFlag featureName="Add Impediment" type="flag"> */}
                {/* <Button
                size="xs"
                color="secondary"
                handleClick={(e) => {
                  
                }}
              >
                <DocumentPlusIcon className="h-5 w-5" />
                <span className="hidden xs:block ml-2">Add Impediment</span>
              </Button> */}
                {/* </FeatureFlag> */}
              </div>
              {/* <div className="flex items-center space-x-3 px-3 py-3"></div> */}
            </div>
            {/* <Paragraph>
                Here you can select all impediments that apply to your team, add
                burden and effort to resolve the impediment, link them to
                competencies, and classify them. Once you resolve the impedment,
                mark it complete so you can see it reflected in your burden
                burndown.{" "}
              </Paragraph> */}
            <div className="relative inline-flex mr-4">
              <Tabs
                tabs={[
                  {
                    name: "My impediments",
                    current: selectedTab,
                  },
                  {
                    name: "All impediments",
                    current: selectedTab,
                  },
                ]}
                selectedTab={selectedTab}
                setSelectedTab={onToggleChange}
              />
            </div>
            <div className="relative w-1/8 inline-flex mr-4 ">
              <input
                id="search"
                name="search"
                type="search"
                autoComplete="search"
                placeholder="Search by description"
                value={searchValue}
                onChange={(e) => {
                  handleSearchList(e, "Description");
                }}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-3 flex items-center">
                {searchValue && searchValue.length > 0 && (
                  <XMarkIcon
                    className="cursor-pointer inline h-5 w-5 text-gray-400"
                    onClick={() => {
                      handleClearAllSelections("Description");
                    }}
                  />
                )}
              </div>
            </div>
            {profile.tenantInfo.type == "admin" && (
              <div className="relative w-1/8 inline-flex mr-4 ">
                <input
                  id="filterReportedBy"
                  name="filterReportedBy"
                  type="search"
                  autoComplete="search"
                  placeholder="Search by reported by"
                  value={searchReportedByValue}
                  onChange={(e) => {
                    handleSearchList(e, "ReportedBy");
                  }}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <div className="absolute inset-y-0 right-3 flex items-center">
                  {searchReportedByValue &&
                    searchReportedByValue.length > 0 && (
                      <XMarkIcon
                        className="cursor-pointer inline h-5 w-5 text-gray-400"
                        onClick={() => {
                          handleClearAllSelections("ReportedBy");
                        }}
                      />
                    )}
                </div>
              </div>
            )}

            {/* {profile.tenantInfo.type == "admin" && (
              <div className="relative inline-flex mr-4 ">
                <Toggle
                  checked={dedupeMode}
                  onChange={handleSetDedupeMode}
                  label={"Compact Mode"}
                />
              </div>
            )} */}
          </div>

          {loading ? (
            <Skeleton type="loader" />
          ) : data && data.length > 0 ? (
            <ImpedimentList
              //render methods
              selectedRow={selectedRow}
              setSelectedRow={(item) => {
                setSelectedRow(item);
              }}
              RenderInfoColumn={RenderInfoColumn}
              RenderTeamName={(row) => {
                return (
                  <RenderTeamName
                    row={row}
                    teams={teams}
                    setReportedByTeamModalOpen={setReportedByTeamModalOpen}
                    handleCommandClick={handleCommandClick}
                    updateData={updateData}
                    profile={profile}
                    // oldName={row.Team}
                    // teamName={row.Claimed_by_team.tenantTitle}
                  />
                );
              }}
              RenderImpactedTeams={(row) => {
                return <RenderImpactedTeams row={row} />;
              }}
              RenderImpedimentTypeList={(row, p) => {
                return <RenderImpedimentTypeList row={row} profile={p} />;
              }}
              RenderCompetencyList={(item) => {
                return (
                  <RenderCompetencyList
                    row={item}
                    competencies={competencies}
                    updateData={updateData}
                    profile={profile}
                  />
                );
              }}
              RenderScopeList={(row) => {
                return <RenderScopeList row={row} profile={profile} />;
              }}
              RenderNumberColumn={(row, field) => {
                return (
                  <RenderNumberColumn
                    row={row}
                    handleOnBlur={handleOnBlurV2}
                    handleTextColumnValueChange2={onFieldChange}
                    columnId={field}
                    profile={profile}
                  />
                );
              }}
              RenderActions={(item, idx) => {
                return (
                  <RenderActions
                    updateData={updateData}
                    setUpdatedId={setUpdatedId}
                    dedupeMode={dedupeMode}
                    row={item}
                    showDelete={true}
                    showViewDetails={false}
                    showResolveImpediment={false}
                    showClaim={false}
                    // rowIndex={idx}
                    profile={profile}
                    setSelectedRow={(item) => {
                      setSelectedRow(item);
                    }}
                  />
                );
              }}
              renderSubComponent={(row, field) => {
                return (
                  <RenderSubComponent
                    row={row}
                    handleOnBlur={handleOnBlur}
                    field={field}
                  />
                );
              }}
              RenderTextField={(row, field) => {
                return (
                  <RenderTextField
                    row={row}
                    handleOnBlur={handleOnBlur}
                    field={field}
                    placeholder={"Enter a title"}
                    data={employees}
                  />
                );
              }}
              //end render methods
              dedupe={dedupeMode}
              columns={columns}
              profile={profile}
              competencies={competencies}
              updatedId={updatedId}
              updateMyData={updateMyData}
              setUpdatedId={setUpdatedId}
              skipReset={skipResetRef.current}
              updateData={updateData}
              initialData={data ? data.slice(0, limit) : []} //this could also be unclaimedImpediments
              items={data} //this could also be unclaimedImpediments
              page_size={10}
              size_limit={10}
              fetch_limit={10}
              loading={loading}
              fromDashboard={true}
              showFooter={true}
              toggleValue={toggleValue}
              hasSubComponent={true}
              onToggleChange={(value) => {
                onToggleChange(value);
              }}
              from={"list"}
            />
          ) : (
            <div className="flex flex-col pt-20 items-center justify-center">
              No impediments found
            </div>
          )}
          {/* {dedupeMode && ( */}
          {data && data.length > 0 && (
            <div className="fixed w-[33vw] bottom-0 right-0 top-[172px] ">
              <RenderImpedimentSidebar
                dedupeMode={dedupeMode}
                row={selectedRow}
                updateData={updateData}
                profile={profile}
                RenderNewReportedByTeams={(row) => {
                  return (
                    <RenderNewReportedByTeams
                      row={row}
                      teams={teams}
                      setReportedByTeamModalOpen={setReportedByTeamModalOpen}
                      handleCommandClick={handleCommandClick}
                      updateData={updateData}
                      profile={profile}
                    />
                  );
                }}
                RenderTeamName={(row) => {
                  return (
                    <RenderTeamName
                      row={row}
                      teams={teams}
                      setReportedByTeamModalOpen={setReportedByTeamModalOpen}
                      handleCommandClick={handleCommandClick}
                      updateData={updateData}
                      profile={profile}
                    />
                  );
                }}
                RenderImpactedTeams={(row) => {
                  return <RenderImpactedTeams row={selectedRow} />;
                }}
                RenderImpedimentTypeList={(row, p) => {
                  return (
                    <RenderImpedimentTypeList row={selectedRow} profile={p} />
                  );
                }}
                RenderCompetencyList={(item) => {
                  return (
                    <RenderCompetencyList
                      row={selectedRow}
                      competencies={competencies}
                      updateData={updateData}
                      profile={profile}
                      from={"sidebar"}
                    />
                  );
                }}
                RenderScopeList={(row) => {
                  return (
                    <RenderScopeList row={selectedRow} profile={profile} />
                  );
                }}
                RenderNumberColumn={(row, field) => {
                  return (
                    <RenderNumberColumn
                      row={row}
                      handleOnBlur={handleOnBlurV2}
                      handleTextColumnValueChange2={onFieldChange}
                      columnId={field}
                      profile={profile}
                    />
                  );
                }}
                RenderActions={(item, idx) => {
                  return (
                    <RenderActions
                      dedupeMode={dedupeMode}
                      component="sidebar"
                      updateData={updateData}
                      setUpdatedId={setUpdatedId}
                      row={item}
                      showDelete={false}
                      showViewDetails={false}
                      showResolveImpediment={true}
                      showClaim={true}
                      // rowIndex={idx}
                      profile={profile}
                      setSelectedRow={(item) => {
                        setSelectedRow(item);
                      }}
                    />
                  );
                }}
                renderSubComponent={(row, field) => {
                  return (
                    <RenderSubComponent
                      row={row}
                      handleOnBlur={handleOnBlur}
                      field={field}
                    />
                  );
                }}
                RenderTextField={(row, field) => {
                  return (
                    <RenderTextField
                      row={row}
                      handleOnBlur={handleOnBlur}
                      field={field}
                      placeholder={"Enter a title"}
                      data={employees}
                    />
                  );
                }}
              />
            </div>
          )}
          {/* )} */}

          {/* <Table
            columns={columns}
            updatedId={updatedId}
            updateMyData={updateMyData}
            skipReset={skipResetRef.current}
            updateData={updateData}
            data={employees} //this could also be unclaimedImpediments
            loading={loading}
            fromDashboard={true}
            showFooter={true}
            renderSubComponent={(row) => {
              return (
                <RenderSubComponent row={row} handleOnBlur={handleOnBlur} />
              );
            }}
            toggleValue={toggleValue}
            hasSubComponent={true}
            onToggleChange={(value) => {
              onToggleChange(value);
            }}
          /> */}
        </div>
      </div>
      <ModalBasic
        id="add-impediment-modal"
        modalOpen={impedimentModalOpen}
        setModalOpen={setImpedimentModalOpen}
        title={"Impediments"}
        showClose={true}
        showFooter={true}
        closeButtonText="Close"
        scrollable={true}
        size="lg"
      >
        <AssessmentTemplateQuestionEditor
          assessment_type={"impediment"}
          selectedCompetency={null}
          competencies={competencies}
          modalOpen={impedimentModalOpen}
          questions={impedimentModalOpen && []}
          addQuestionRef={null} //{addQuestionRef}
          refreshQuestions={updateImpediments} //{refreshQuestions}
          setQuestions={updateImpediments} //{setQuestions}
          modalItem={null}
          title={"Impediment "}
          description={"Add a new impediment below"}
          create={true}
          from={"impediments"}
        />
      </ModalBasic>
      {/* <ModalBasic
        id="add-reported-by-team-modal"
        modalOpen={reportedByTeamModalOpen}
        setModalOpen={setReportedByTeamModalOpen}
        title={"Reported by team"}
        showClose={true}
        showFooter={true}
        closeButtonText="Close"
        scrollable={true}
        size="sm"
      >
        Teams selector
      </ModalBasic> */}
      {/* <CommandPalette
        open={reportedByTeamModalOpen}
        setOpen={setReportedByTeamModalOpen}
      /> */}
    </>
  );
}

export default Impediments;
