import { useState, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Icon } from "@iconify/react";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import Skeleton from "../../../components/Skeleton";
import { dragAndDrop } from "../../../modules/_dragAndDrop";
import { _impediments } from "../../../modules/impediments";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import Button from "../../../components/Button";
import { AssignPallette } from "../../../components/AssignPallette";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
export default function DraggableImpedimentList({
  project,
  size_limit,
  fetch_limit,
  dedupe,
  // items,
  setSelectedRow,
  setUpdatedId,
  columns,
  profile,
  updatedId,
  updateData,
  updateOrder,
  competencies,
  loading,
  fromDashboard,
  showFooter,
  toggleValue,
  hasSubComponent,
  onToggleChange,
  selectedRow,
  handleTextColumnValueChange2,
  handleOnBlur,
  renderSubComponent,
  RenderTextField,
  RenderTeamName,
  RenderActions,
  updateSortOrder,
  updateAssignedTo,
  selectedPlan,
  ...props
}) {
  // const { items } = props;
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(data.length);
  const [hasMore, setHasMore] = useState(true);
  const [dedupeMode, setDedupeMode] = useState(dedupe);
  const [dragging, setDragging] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);

  const [loader, setLoader] = useState(loading);
  const [cursor, setCursor] = useState(null);
  const [allTeamImpediments, setAllTeamImpediments] = useState([]);
  const [winReady, setwinReady] = useState(false);
  const [selected, setSelected] = useState(null);
  const infiniteRef = useRef(null);
  let scrollParentRef = useRef(null);
  const dragItem = useRef();
  const dragOverItem = useRef();

  //   useEffect(() => {

  //     setHasMore(true);
  //   }, [data]);
  useEffect(() => {
    setSelected(props.selectedRow);
  }, [props.selectedRow]);

  useEffect(() => {
    setDedupeMode(dedupe);
  }, [dedupe]);

  useEffect(() => {
    setOffset(data.length);
  }, [offset]);

  useEffect(() => {
    setwinReady(true);
  }, [props.winReady]);

  useEffect(() => {
    setAllData(props.items); //all data
    setData(props.initialData); //sliced

    // setData(props.items.slice(0, LIMIT));
  }, [props.items, props.initialData]);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  const handleItemClick = (e, item) => {
    e.preventDefault();
    // props.onItemClick(project, item);
  };

  const handleLoadMore1 = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      setData(data.concat(Array.from({ length: 20 })));
      // this.setState({
      //   items: items.concat(Array.from({ length: 20 }))
      // });
    }, 1500);
  };

  const handleLoadMore = () => {
    const newData = allData.slice(0, data.length + 10);
    // const combine = [...data, ...allData.slice(data.length, data.length + 50)];
    setData([...data, ...allData.slice(data.length, data.length + 50)]);
    setHasMore(newData.length < allData.length);
    return newData;
  };

  const combineArrays = (arr1, arr2) => {
    let nextData = arr2.slice(arr1.length, arr1.length + 10);
    return [...arr1, ...nextData];
  };

  const fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setLoader(true);
    setTimeout(() => {
      setData(combineArrays(data, allData));

      setLoader(false);
    }, 1000);
  };

  const onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
    //get item from state.data by index
    const item = data[index];
    setDraggedItem(item);
    //set draggedItem in state to the item being dragged

    setTimeout(() => {
      setDragging(true);
    }, 300);

    // e.dataTransfer.setData("text/plain", index);
    // e.dataTransfer.setData("text/html", index);
    // e.dataTransfer.setData("text/uri-list", index);
    // e.dataTransfer.setData("text/uri-list", index);
  };
  const handleDrag = (e) => {};

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const onDragLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const onDragEnd = (e) => {
    e.preventDefault();
    // const startIndex = e.dataTransfer.getData("index");
    // const droppedIndex = e.destination.index;
    //
    // // update the data
    // const items = data.filter((item, i) => i !== droppedIndex);
    // items.splice(startIndex, 0, data[droppedIndex]);
    // setData(items);
    setDragging(false);
    setDraggedItem(null);
    // updateOrder(items);
  };
  const onDrop = (e) => {
    //why is my e.destination undefined?
    //get the DroppedIndex
    e.preventDefault();
    const startIndex = e.dataTransfer.getData("index");
    const droppedIndex = e.dataTransfer.getData("index");

    // update the data

    //removed draggedItem from data

    const items = data.filter((item, i) => item._id !== dragItem._id);

    items.splice(startIndex, 0, draggedItem);

    setData(items);
    setDragging(false);
  };
  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    // setDragging(true);
    dragOverItem.current = position;
  };

  const drop = () => {
    // let copyListItems = dragAndDrop.drop(data);
    //if the drag and the drop are the same, do nothing
    if (dragItem.current === dragOverItem.current) {
      return;
    }
    const copyListItems = [...data];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);

    setData(copyListItems);
    updateSortOrder(copyListItems);
    dragItem.current = null;
    dragOverItem.current = null;
    // dragItem.current = null;
    // dragOverItem.current = null;
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setData(items);
    updateSortOrder(items);
  }

  const handleAssignUser = async (item, card) => {
    let items = Array.from(data);
    //find the item in the array
    let index = items.findIndex((i) => {
      return i.impedimentId === card.impedimentId;
    });

    //update the card with the new assigned user
    items[index].assigned_to = {
      id: item._id || item.id,
      name: item.name,
      email: item.email,
    };

    updateAssignedTo(items[index]);
  };

  return (
    <div
      //droppable area
      // when changing dedupeMod, the width of the table changes to accomodate the dedupe column
      // this causes the table to shift left and right.  This transition helps to smooth out the shift
      className={`pr-[33vw] 
      
      transition-all duration-500 ease-in-out
      `}
    >
      {loader && (
        // toast notication indicates loading
        <div
          className="fixed inset-x-0 inset-y-12 flex items-end justify-center 
        pl-4 py-6  pointer-events-none sm:p-6 sm:items-start sm:justify-end"
        >
          <div
            className="max-w-sm w-full 
           pointer-events-auto overflow-hidden"
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {/* <!-- Heroicon name: check-circle --> */}
                  <Skeleton type="spinner" color="indigo" forButton />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">
                    Loading...
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Please wait while we load the data for you.
                  </p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    type="button"
                    className="inline-flex text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close</span>
                    {/* <!-- Heroicon name: x --> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        id="scrollableDiv"
        style={{ height: "calc(100vh - 11rem)" }}
        className=" bg-white sm:rounded-md  scrollbar-thin scrollbar-thumb-slate-300 
        scrollbar-track-slate-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full overflow-visible"
      >
        <div className="flex h-12  justify-between items-center py-1 sticky top-0 z-10  border-b border-gray-200  px-4 py-1 text-sm ">
          {/* <div className="flex text-sm px-2 justify-between py-3 border-b border-gray-200"> */}
          <div className="font-medium text-base "> Impediments</div>

          <div>
            {selectedPlan && selectedPlan.name && (
              <>
                {" "}
                <Button
                  color="secondary-text"
                  handleClick={() => {
                    props.addNewImpediment();
                  }}
                >
                  + Create
                </Button>
                <Button
                  color="secondary-text"
                  handleClick={() => {
                    props.addExistingImpediment();
                  }}
                >
                  + Select
                </Button>
              </>
            )}
          </div>
        </div>
        {/* <InfiniteScroll
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={true}
          loader={() => {
            return (
              <div>
                <Skeleton type="spinner" />
              </div>
            );
          }}
          scrollableTarget="scrollableDiv"
        > */}
        {!data || data.length === 0
          ? data.length > 0 && (
              <div className="p-4 w-[50%]">
                <h1 className="font-semibold">No impediments</h1>
                <p>
                  You currently do not have any impediments claimed for this
                  competency. Please claim your impediments on the impediments
                  page.
                </p>
              </div>
            )
          : null}
        {winReady == true ? (
          <DragDropContext
            onDragEnd={handleOnDragEnd}
            // onDragEnd={(e) => {
            //   // e.preventDefault();
            //   // e.stopPropagation();
            //   drop();
            // }}
          >
            <Droppable
              droppableId="droppableImpediments"
              // onDrop={drop}
              // onDragEnd={handleOnDragEnd}
              // onDrop={(e) => {
              //   // e.preventDefault();
              //   // e.stopPropagation();
              //   drop();
              // }}
              // onDragEnd={(e) => {
              //   // e.preventDefault();
              //   // e.stopPropagation();
              //   drop();
              // }}
            >
              {(provided) => (
                <ul
                  // B-DND
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // B-DND

                  role="list"
                  className={`divide-y divide-gray-200 ${
                    dragItem && "cursor-grabbing"
                  } `}
                  style={{
                    cursor: dragItem && dragItem.current ? "grabbing" : "grab",
                  }}
                >
                  {data &&
                    data.length > 0 &&
                    data.map((card, index) => {
                      let item = card.impediment;

                      if (item === undefined) return null;
                      return (
                        <Draggable
                          key={item && item._id && item._id}
                          draggableId={item && item._id && item._id}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              // B-DND
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              // B-DND

                              index={index}
                              key={item?._id}
                              className={`px-3 ${"py-2"}
                  ${
                    selectedRow && selectedRow?._id == item?._id
                      ? "bg-gray-50"
                      : "bg-white"
                  } hover:bg-gray-50 
                  transition-all duration-500 ease-in-out 
                  ${" cursor-grab"}
              
                  
                  `}
                              onClick={(e) => setSelectedRow(item)}
                            >
                              <div className="  transition-all duration-500 ease-in-out">
                                <div
                                  id="row-1"
                                  className="flex items-center  sm:space-y-1 md:flex-row pl-4  justify-between  "
                                >
                                  <div
                                    id="row-1-col-1"
                                    className="flex  text-sm items-center"
                                  >
                                    <div className="-ml-6">
                                      <Icon
                                        icon="clarity:drag-handle-line"
                                        className="inline h-6 w-6 text-gray-500 mr-1 cursor-grab"
                                      />
                                    </div>
                                    {item &&
                                    item.is_team_specific &&
                                    item.is_team_specific !== undefined &&
                                    item.is_team_specific === true ? (
                                      <Icon
                                        icon="mdi:user-lock"
                                        className="flex flex-0 h-4 w-4 text-indigo-500 mr-2"
                                      />
                                    ) : (
                                      <UserGroupIcon className="flex h-4  text-indigo-500 mr-2" />
                                    )}
                                    <div className="flex  whitespace-nowrap mr-3">
                                      IM-{item.Index}
                                    </div>

                                    {/* badge */}
                                    {card.impediment_status && (
                                      <span
                                        className={`ml-2 inline-flex whitespace-nowrap items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                          card.impediment_status === "Closed"
                                            ? "bg-green-100 text-green-800"
                                            : card.impediment_status ===
                                              "In Progress"
                                            ? "bg-yellow-100 text-yellow-800"
                                            : "bg-gray-100 text-gray-800"
                                        }`}
                                      >
                                        {card.impediment_status}
                                      </span>
                                    )}

                                    {/* {RenderTextField(item, "Title")} */}
                                  </div>

                                  <div
                                    id="row-1-col-1"
                                    className=" flex  text-xs "
                                  >
                                    {card.assigned_to &&
                                    card.assigned_to !== undefined ? (
                                      <Button
                                        size="xs"
                                        color="accent-text"
                                        className="text-accent-800"
                                      >
                                        <div className="flex items-center">
                                          <Icon
                                            icon="iconoir:remove-user"
                                            className="w-4 h-4 mr-2 "
                                          />
                                          <span> {card.assigned_to.name}</span>
                                        </div>
                                      </Button>
                                    ) : (
                                      <AssignPallette
                                        row={card}
                                        records={props.users}
                                        handleSelect={(item) => {
                                          setSelectedRow(card);
                                          handleAssignUser(item, card);
                                        }}
                                        profile={profile}
                                      />
                                    )}

                                    {/* {RenderActions(
                                      item,
                                      updateData,
                                      setUpdatedId
                                    )} */}
                                  </div>
                                </div>
                                <div id="row2" className="flex items-center">
                                  <div className="flex flex-row">
                                    <span className="pl-4 ml-2">
                                      {" "}
                                      {item.Title}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                        </Draggable>
                        // </div>
                      );
                    })}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        ) : null}
        {/* </InfiniteScroll> */}
      </div>
      {/* </ul> */}
    </div>
  );
}
