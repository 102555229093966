import { Icon } from "@iconify/react";
import { useState } from "react";
import Badge from "../../components/Badge";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ tabs, count, icon, secure, ...props }) {
  // const [selectedTab, setSelectedTab] = useState("View All");
  const setSelectedTab = props.setSelectedTab;
  const selectedTab = props.selectedTab;
  const isVisible = (tab) => {
    if (secure && secure == true) {
      return (
        tab.view.includes(props.profile.tenantRole) ||
        props.profile.role == "Administrator"
      );
    } else {
      return true;
    }
  };
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md 	cursor-pointer;"
          defaultValue={
            tabs && tabs.length > 0 && tabs.find((tab) => tab.current).name
          }
          onChange={(e) => {
            // e.preventDefault();
            setSelectedTab(e.target.value);
          }}
        >
          {tabs.map((tab) => {
            if (isVisible(tab)) {
              return <option key={tab.name}>{tab.name}</option>;
            }
          })}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab, index) => {
            if (isVisible(tab) && tab.visible === true) {
              return (
                <a
                  key={tab.name}
                  onClick={(e) => {
                    setSelectedTab(tab.name, index);
                  }}
                  // href={tab.href}
                  className={classNames(
                    tab.name === selectedTab
                      ? "bg-gray-100 text-gray-700"
                      : "text-slate-500 hover:text-slate-700",
                    "flex items-center space-x-2 px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.icon && tab.icon !== null && (
                    <span className="inline-flex">
                      <Icon icon={tab.icon} />
                    </span>
                  )}{" "}
                  <span>{tab.name}</span>
                  <span> {tab.count}</span>
                  {tab.status && tab.status == "beta" && (
                    <span className="ml-2 capitalize rounded bg-indigo-100 px-2 text-xs font-semibold leading-5 text-indigo-800">
                      {tab.status}
                    </span>
                  )}
                  {/* {tab.count && (
                    <span className="flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                     
                    </span>
                  )} */}
                </a>
              );
            }
          })}
        </nav>
      </div>
    </div>
  );
}
